import { createSlice } from '@reduxjs/toolkit'


const initialState = {
  sectionList: []
}

export const sectionList = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    sectionList: (state, action) => {     
        state.data = action.payload
    }  
  },
})

export const { increment } = sectionList.actions

export default sectionList.reducer