import axios from 'axios'
import React, { useEffect, useState, useRef, Fragment } from 'react'
import { useParams, Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { EyeIcon, TrashIcon, ArrowPathIcon, ExclamationTriangleIcon, PencilSquareIcon, PlusIcon } from '@heroicons/react/24/solid'

import { Dialog, Transition } from '@headlessui/react'

function CatFood({ reloading }) {
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [open, setOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [plusOpen, setPlusOpen] = useState(false)
  const [currentPlus, setCurrentPlus] = useState(null)
  const [currentEditData, setCurrentEditData] = useState(null)
  const [recipes, setRecipes] = useState([])
  const cancelButtonRef = useRef(null)
  const { menu_id } = useParams()
  const [sections, setSections] = useState([])

  const [changeData, setChangeData] = useState([])

  const five = [1, 2, 3, 4, 5]

  const handlePlusFetch = (id, k) => {
    
    axios.get(`${process.env.REACT_APP_SERVER_HOST}/admin/menu/section/${id}`, {
      headers: {
        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
      }
    }).then((res) => {
      if (res.data.meals.length === k - 1 ) {
        setCurrentPlus({ section_id: id, meal_pos: k }); 
        setPlusOpen(true)
      } else {
        warningAlert()
      }
    }).catch((err) => { 
      console.log(err)
    })
  }





  const fetchRecipe = async() => {
    setLoading(true)
    await axios.get(`${process.env.REACT_APP_SERVER_HOST}/admin/menu/${menu_id}/meals/`, {
      headers: {
        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
      }
    }).then((res) => {
      setLoading(true)
      setRecipes(res.data)  
      setCount(res.data.length)
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })
  }

  const fetchData = async() => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/admin/menu/section/`, {
        headers: {
          "Authorization": `Bearer ${sessionStorage.getItem('token')}`
        }
      })

      const { data } = res
      const filterData = data.filter(item => item.category === Number(menu_id))
      
      setSections(filterData)
 
      
    } catch (error) {
      console.log(error)
    }
  }

  if (reloading === true) fetchData()

  useEffect(() => {

    const handleEsc = (event) => {
      if (event.keyCode === 27) {
       setChangeData([])
     }
   };
   window.addEventListener('keydown', handleEsc);
    fetchData()
    fetchRecipe()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  // const handleTwoData = (event) => {
  //   console.log(`SECTION ID: ${event.target.id}`)
  //   console.log(`MEAL    ID: ${event.target.value}`)
  //   var updatedList = [...changeData];      

  //   if (event.target.checked) {
  //     updatedList = [...changeData, { section_id: Number(event.target.id), meal_id: event.target.value ? Number(event.target.value) : null }];
  //   } else {
  //     updatedList.splice(changeData.indexOf({ section_id: Number(event.target.id), meal_id: event.target.value ? Number(event.target.value) : null }), 1);
  //   }
 
  //   setChangeData(updatedList);
  //   if (updatedList.length >= 2) return setOpen(true)


  //   console.log(changeData)
    
  // }; 

  const handleClickData = (item, meal) => {
    setChangeData(changeData => [...changeData, { section_id: item.id, meal: meal!==undefined ? meal : null }]); changeData.length >= 1 ? setOpen(true) : setOpen(false)
  }


  const handleChangeData = async() => {
    setLoading(true)
    await axios.post(`${process.env.REACT_APP_SERVER_HOST}/admin/menu/section/meal/replace/`, {
      first_section_id: changeData[0].section_id,
      last_section_id: changeData[1].section_id,
      first_meal_id: changeData[0].meal.id, 
      last_meal_id: changeData[1].meal.id,
    }, {
      headers: {
        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
      }
    })
    .then((res) => {
      setLoading(false)
      if (res.statusText === "OK") succesAlert("Muvaffaqqiyatli almashtirildi!")
      setOpen(false)
      setChangeData([])
      fetchRecipe()
      fetchData()
    }).catch((error) => {
      setLoading(false)
      console.log(error)
    })


  }


  const handleEditSubmit = async(item) => {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_HOST}/admin/menu/section/meal/replace/`,
      data: {
        first_section_id: currentEditData.section_id,
        first_meal_id: currentEditData.meal.id,
        last_meal_id: item
      }, 
      headers: {
            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
        }
    }).then((res) => {
      setLoading(false)
      if (res.statusText === "OK") succesAlert("Muvaffaqqiyatli o'zgartirildi!")   
      setEditOpen(false)   
      fetchData()
      fetchRecipe()
      setCurrentEditData(null)
    }).catch((error) => {
      console.log(error)
      setLoading(false)
    })

  }


  const handleAddSubmit = async(item) => {
    console.log(item)


    await axios({
      method: "patch",
      url: `${process.env.REACT_APP_SERVER_HOST}/admin/menu/section/meal/add/section/${currentPlus.section_id}/`,
      data: {
        meal: item.id,
        position: currentPlus.meal_pos
      }, 
      headers: {
            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
        }
    }).then((res) => {
      setLoading(false)
      if (res.statusText === "OK") succesAlert("Muvaffaqqiyatli qo'shildi!")   
      setPlusOpen(false)
      fetchData() 
      fetchRecipe() 
      window.location.reload()
      setCurrentPlus(null)
    }).catch((error) => {
      console.log(error) 
      setLoading(false)
    })
  }


  const deleteItemAlert = async(section_id, meal) => {
    Swal.fire({
      title: 'Ishonchingiz komilmi?',
      text: "Keyin qayta tiklay olmasligingiz mumkin!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ha, o\'chirmoqchiman!',
      cancelButtonText: 'Orqaga'
    }).then((result) => {
      if (result.isConfirmed) {
        
         axios.delete(`${process.env.REACT_APP_SERVER_HOST}/admin/menu/section/meal/${section_id}/${meal.id}/`, {
          headers: {
                "Authorization": `Bearer ${sessionStorage.getItem('token')}`
              }      
        }).then((res) => {
          if (res.status === 204) {
            Swal.fire(
              'Deleted!',
              'Section muvaffaqqiyatli o\'chirildi',
              'success'
            )
            fetchData()
            fetchRecipe()
            window.location.reload() 
          }
        }).catch((err) => {
          console.log(err)
        })
  
      }
    })
  }
  


  
 
  
const deleteAlert = async(item) => {
  Swal.fire({
    title: 'Ishonchingiz komilmi?',
    text: "Keyin qayta tiklay olmasligingiz mumkin!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Ha, o\'chirmoqchiman!',
    cancelButtonText: 'Orqaga'
  }).then((result) => {
    if (result.isConfirmed) {
      
      axios.delete(`${process.env.REACT_APP_SERVER_HOST}/admin/menu/section/${item}/`, {
        headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }      
      }).then((res) => {
        Swal.fire(
          'Deleted!',
          'Section muvaffaqqiyatli o\'chirildi',
          'success'
        )
        fetchData()
        window.location.reload()
      }).catch((err) => {
        console.log(err)
      })

    }
  })
}


const succesAlert = (title) => {
  Swal.fire({
    icon: 'success',
    title,
    width: 360,
    showConfirmButton: false,
    timer: 1500
  })
}


const warningAlert = () => {
  Swal.fire(
    'Ogohlantirish?',
    'Bundan oldingi katakga mahsulot qo`shing?',
    'question'
  )
}






  return (
    <div>

      { sections.length <= 0 ? <div className="w-full h-96 text-2xl flex flex-col justify-center items-center"> Malumot yo'q </div> : sections.map((item, index) => (
        <div key={index} className="flex flex-row w-full justify-start items-center">
          
        <div className="flex w-1 ml-2 px-2 text-xs border float-right rounded-full flex-row justify-center items-center">
           {item.position}
        </div>
        
            {five.map(k => item.meals.find(a => a.position === k) ?  <div key={k} className={`w-1/5 my-1 h-full ${item.meals.find(a => a.position === k).id === item.main_meal ? "bg-green-300" : ""} flex p-1 rounded-sm mx-1 flex-row border justify-start items-center`}>
              <div className="w-0.1/5 mx-1 text-xs bg-yellow-400 px-1">
                {item.meals.find(a => a.position === k).position}
              </div>
              <div className="flex w-1/5 flex-col justify-start items-start">
                  <img src={item.meals.find(a => a.position === k).thumbnail} width={30} height={30} alt="" />
              </div>

              <div className="flex ml-1 w-4/5 flex-col justify-start items-start">
                <div className="flex text-xs font-bold flex-col justify-center items-center">
                {item.meals.find(a => a.position === k).title}
                </div>
                <div className="flex text-xs flex-col justify-center items-center">
                {item.meals.find(a => a.position === k).summa}
                </div>

              </div>

                {/* <input type="checkbox" onChange={handleTwoData} id={item.id} value={item.meals[k] ? item.meals[k].id : undefined } className={`ml-2 w-0.5/5 ${ changeData.length >= 2 && !changeData.find(z => z.meal_id === item.meals[k].id) ? "pointer-events-none hidden" : ""} border shadow w-4 h-4`} /> */}
                {/* <ArrowPathIcon width={15} height={15} /> */}

               { <button className={`${changeData.find(m => m.meal.id === item.meals.find(a => a.position === k).id) ? "bg-blue-600 pointer-events-none text-white" : ""} p-1 rounded-sm text-center`} onClick={() => handleClickData(item, item.meals.find(a => a.position === k))}> <ArrowPathIcon className="w-4 h-4 hover:text-blue-300" /> </button>  } 
               { <button className={`p-1 rounded-sm text-center active:bg-blue-600 active:text-white`} onClick={() => { setCurrentEditData({ section_id: item.id, meal: item.meals.find(a => a.position === k) }); setEditOpen(true) }}> <PencilSquareIcon className="w-4 h-4 hover:text-blue-300" /> </button>  } 
               {/* { <button onClick={() => deleteItemAlert(item.id, item.meals.find(a => a.position === k))}> <TrashIcon className="w-4 ml-1 h-4 hover:text-red-300" /> </button> }  */}

           </div>:  <div key={k} className={`w-1/5 my-1 h-full flex p-1 rounded-sm mx-1 flex-row border justify-start items-center`}>
              <div className="w-0.1/5 mx-1 text-xs bg-yellow-400 px-1">
                {k}
              </div>
              <div className="w-full py-0.5 text-center">
             { <button className={`p-1 rounded-sm text-center active:bg-blue-600 active:text-white`} onClick={() => handlePlusFetch(item.id, k)}> <PlusIcon className="w-4 h-4 hover:text-blue-300" /> </button>  } 
             </div>
                {/* <input type="checkbox" onChange={handleTwoData} id={item.id} value={item.meals[k] ? item.meals[k].id : undefined } className={`ml-2 w-0.5/5 ${ changeData.length >= 2 && !changeData.find(z => z.meal_id === item.meals[k].id) ? "pointer-events-none hidden" : ""} border shadow w-4 h-4`} /> */}
                {/* <ArrowPathIcon width={15} height={15} /> */}

               {/* {<button className={`${changeData.find(m => m.meal.id === item.meals[k].id) ? "bg-blue-600 text-white" : ""} p-1 rounded-sm text-center`} onClick={() => handleClickData(item, item.meals[k])}> <ArrowPathIcon className="w-4 h-4 hover:text-blue-300" /> </button>  }  */}
               {/* { <button className={`p-1 rounded-sm text-center active:bg-blue-600 active:text-white`} onClick={() => { setCurrentEditData({ section_id: item.id, meal: item.meals[k] }); setEditOpen(true) }}> <PencilSquareIcon className="w-4 h-4 hover:text-blue-300" /> </button>  }  */}
               {/* { <button onClick={() => deleteItemAlert(item.id, item.meals[k].id)}> <TrashIcon className="w-4 ml-1 h-4 hover:text-red-300" /> </button>  }  */}

           </div> ) }
            
            

        
          <div className="flex float-right rounded-sm flex-row justify-end items-center">
            <div className="flex flex-row justify-center items-center">
                <Link to={`/menu/${item.category}/${item.id}`} className="flex flex-col justify-center items-center border-1 border hover:border-gray-400 active:bg-slate-400">
                    <EyeIcon className="w-4 h-4 m-2"/>
                </Link>
                <button onClick={() => deleteAlert(item.id)} className="flex flex-col text-red-400 justify-center items-center border-1 border hover:border-gray-400 active:text-white active:bg-red-400">
                    <TrashIcon className="w-4 h-4 m-2"/>
                </button>
            </div>
            <div className="flex flex-col justify-center items-center ml-1">
            </div>
          </div>   
          
        </div>  
      )) }




<Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl w-1/4 transition-all sm:my-8 sm:w-full sm:max-w-lg">

             {changeData.length >= 2 ? <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div>
                    <div className="mx-auto flex flex-row justify-start items-center">
                      <ExclamationTriangleIcon className="h-10 w-10 text-yellow-600" aria-hidden="true" />
                      <Dialog.Title as="h3" className="text-lg mx-5 font-medium leading-6 text-gray-900">
                        Uchbu ikkita mahsulot o'rni almashadi!
                      </Dialog.Title>
                    </div>
                    <div className="mt-3">

                      <div className="flex">
                        <div className="flex-1">
                          <div className="max-w-sm text-center rounded overflow-hidden shadow-lg">
                            <img className="w-full" src={changeData[0].meal.thumbnail} alt="item1" />
                            <div className="px-2 text-sm py-2">
                                {changeData[0].meal.title}
                            </div>
                            <div className="px-2 pb-2">
                              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-xs font-semibold text-gray-700 mr-2 mb-2">
                                {changeData[0].meal.summa} so'm
                              </span>
                            </div>
                          </div>
                        </div>  

                        <div className="mx-8 flex flex-col justify-center items-center">
                          <span className="p-2 px-4 m-3 border"> {changeData[0].position} </span>
                            <ArrowPathIcon className="w-5 h-5" />
                          <span className="p-2 px-4 m-3 border"> {changeData[1].position} </span>
                        </div>
                        <div className="flex-1">
                          <div className="max-w-sm text-center rounded overflow-hidden shadow-lg">
                            <img className="w-full" src={changeData[1].meal.thumbnail} alt="item1" />
                            <div className="px-2 text-sm py-2">
                                {changeData[1].meal.title}
                            </div>
                            <div className="px-2 pb-2">
                              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-xs font-semibold text-gray-700 mr-2 mb-2">
                                {changeData[1].meal.summa} so'm
                              </span>
                            </div>
                          </div>
                        </div>  


                      </div>                     



                    </div>
                  </div>
                </div>:''}  


                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleChangeData}
                  > Almashtirish
                    <ArrowPathIcon className="ml-1" width={20} height={20} />
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => { setOpen(false); setChangeData([]) }}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>




    <Transition.Root show={editOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setEditOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="my-2 px-2 text-center w-full shadow-md overflow-y-auto">
                <h4 className="py-2">Birorta ovqatni tanlang {`( ${count} )`}</h4>
                { recipes.length <= 0 ? <div className="w-full h-48 flex flex-col justify-center items-center"> Malumot mavjud emas! </div> : recipes.map((item, index) => (
                  <div onClick={() => handleEditSubmit(item.id)} key={index} className="flex p-1 hover:bg-blue-400 cursor-pointer rounded-sm border-y flex-row justify-start items-center">
                      <div className="flex px-2 w-1/12 text-xs flex-col justify-start items-start">
                        {index + 1}
                      </div>
                      <div className="flex flex-col w-2.5/12">
                        <img className="rounded-full object-cover w-10 h-10" src={item.thumbnail} alt="" />
                      </div>

                      <div className="flex px-2 w-8/12 flex-col justify-start items-start">
                        <div className="flex  text-sm flex-col justify-center items-center">
                          {item.title}
                        </div>
                        <div className="flex text-xs flex-col justify-center items-center">
                          {item.summa} so'm
                        </div>
                      </div>
                  </div>
                )) }

                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>




    <Transition.Root show={plusOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setPlusOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="my-2 px-2 text-center w-full shadow-md overflow-y-auto">
                <h4 className="py-2">Birorta ovqatni tanlang {`( ${count} )`}</h4>
                { recipes.length <= 0 ? <div className="w-full h-48 flex flex-col justify-center items-center"> Malumot mavjud emas! </div> : recipes.map((item, index) => (
                  <div onClick={() => handleAddSubmit(item)} key={index} className="flex p-1 hover:bg-blue-400 cursor-pointer rounded-sm border-y flex-row justify-start items-center">
                      <div className="flex px-2 w-1/12 text-xs flex-col justify-start items-start">
                        {index + 1}
                      </div>
                      <div className="flex flex-col w-2.5/12">
                        <img className="rounded-full object-cover w-10 h-10" src={item.thumbnail} alt="" />
                      </div>

                      <div className="flex px-2 w-8/12 flex-col justify-start items-start">
                        <div className="flex  text-sm flex-col justify-center items-center">
                          {item.title}
                        </div>
                        <div className="flex text-xs flex-col justify-center items-center">
                          {item.summa} so'm
                        </div>
                      </div>
                  </div>
                )) }

                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>




    </div>
  )
}

export default CatFood