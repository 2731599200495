import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { BarLoader } from 'react-spinners'

function MasterMenu() {

  const [tabs, setTabs] = useState([])
  const [cats, setCats] = useState([])
  const [newdata, setNewData] = useState([])
  const [loading, setLoading] = useState(false)


  //get data
  const fetchData = async () => {
    setLoading(true)
    await axios.get(`${process.env.REACT_APP_SERVER_HOST}/admin/menu/category/`, {
      headers: {
        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
      }
    }).then((res) => {
      const { data } = res
      setCats(data)
      const filterData = data.filter((item) => item.parent === null && item.position !== 1)
      setTabs(filterData)
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })

  }

  //filter data 
  const filterData = (id) => {
    setNewData(cats.filter(item => item.parent === id))
  }

  useEffect(() => {
    fetchData()
  }, [])



  return (
    <div className="flex flex-col justify-center items-center">
      <div className="w-full py-8 px-10"><h1>Menu tuzish</h1></div>
      <div className="container mx-auto flex flex-col justify-center items-center">
        {loading ? <div className="flex flex-col w-full justify-center items-center mt-10 h-[10vh]">
            <BarLoader color="#36d7b7" />
            <h6 className="my-5 text-gray-500">
              Ma'lumotlar yuklanmoqda ...
            </h6>
        </div> : <div className="flex flex-col items-center justify-center max-w-xl">
          <ul className="flex space-x-2">
            {tabs.map((tab) => (
              <li key={tab.id}>
                <button
                  onClick={() => filterData(tab.id)}
                  className="inline-block w-32 mt-10 px-4 py-2 text-gray-600 bg-white rounded border-1 border-gray-700 hover:bg-slate-500 hover:text-white active:bg-slate-700 active:text-white focus:bg-slate-700 focus:text-white"
                >
                  {tab.title}
                </button>
              </li>
            ))}

            <li key={100}>
              <a
                href="/save"
                className="inline-block mt-10 px-4 py-2 border border-slate-100 text-gray-600 bg-white rounded border-1 border-gray-700 hover:bg-slate-500 hover:text-white active:bg-slate-700 active:text-white focus:bg-slate-700 focus:text-white"
              >
                Tanlanganlar
              </a>
            </li>
          </ul>
          <div className="p-3 mt-6 bg-white border-1">
            {newdata.map((item) => (
              <div
                key={item.title}
                className="py-2"
              >
                <Link className="hover:underline hover:text-blue-500" to={`/menu/${item.id}`}>{item.title}</Link>
              </div>
            ))}
          </div>
        </div>}
      </div>

    </div>
  )
}

export default MasterMenu