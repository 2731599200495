import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isSwitch2: false
}


export const switched2 = createSlice({
    name: 'is_switch',
    initialState,
    reducers: {
        switchFunc2: (state, action) => {
            state.isSwitch2 = !state.isSwitch2
        }
    }
})


export const { switchFunc2 } = switched2.actions

export default switched2.reducer