import { Bars3Icon } from '@heroicons/react/24/solid';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './components/Content/Login';
import Sidebar from './components/Content/Sidebar';
import About from './components/Pages/About';
import Blogs from './components/Pages/Blogs';
import Categories from './components/Pages/Categories';
import Gallery from './components/Pages/Gallery';
import Home from './components/Pages/Home';
import MasterMenu from './components/Pages/MasterMenu';
import Profile from './components/Pages/Profile';
import Recipe from './components/Pages/Recipe';
import SavedMeals from './components/Pages/SavedMeals';
import SmsSend from './components/Pages/SmsSend';
import Special from './components/Pages/Special';
import BlogView from './components/Pages/View/BlogView';
import InnerView from './components/Pages/View/InnerMaster';
import MasterView from './components/Pages/View/MasterView';
import RecipeView from './components/Pages/View/RecipeView';
import Saved from './components/Pages/View/Saved';
import ViewCategories from './components/Pages/View/ViewCategory';
import ViewSpecial from './components/Pages/View/ViewSpecial';
import SubCategory from './components/Pages/View/ViewSubCat';
import { showed } from './features/sitecontent/isShow';

function App() {

  const { isShow } = useSelector(state => state.isShow)
  const dispatch = useDispatch()
  return ( 
    sessionStorage.getItem('token')?<BrowserRouter>
    <div> 
      <aside className={`${isShow ? "ml-[-100%]" : ""}  fixed z-10 top-0 pb-3 transition-all px-6 w-full flex flex-col justify-between h-screen border-r bg-white duration-300 md:w-4/12 lg:ml-0 lg:w-[25%] xl:w-[20%] 2xl:w-[15%]`}>
          {sessionStorage.getItem('token')?<Sidebar/>:''}
      </aside>
    
    <div className="ml-auto mb-3 lg:w-[75%] xl:w-[80%] 2xl:w-[85%]">
    <div className="sticky top-0 bg-white lg:py-2.5">
        <div className="flex pt-2 px-2 items-center justify-between space-x-4 2xl:container">
            <h5 hidden className="text-2xl px-10 text-gray-600 font-medium lg:block">
              
            </h5>
            <button onClick={() => dispatch(showed())} className="w-12 h-12 -mr-2-r lg:hidden flex flex-col justify-center">
              <Bars3Icon className="h-10 m-1 w-10 text-blue-500 flex flex-col justify-center"/>
            </button>
        </div>
    </div>

    <div className="px-2 pt-2">
        {/* <div className="h-[80vh] xl:px-5 sm:px-1 border-2 border-dashed border-gray-300 rounded-xl"> */}
        <div className="h-[90vh] xl:px-5 sm:px-1 rounded-xl">
          <Routes> 
                <Route path='/' element={sessionStorage.getItem('token')?<Home/>:<Login/>} />
                <Route path='/about' element={sessionStorage.getItem('token')?<About/>:<Login/>} />
                <Route path='/special' element={sessionStorage.getItem('token')?<Special/>:<Login/>} />
                <Route path='/special/:id' element={sessionStorage.getItem('token')?<ViewSpecial/>:<Login/>} />
                <Route path='/blogs' element={sessionStorage.getItem('token')?<Blogs/>:<Login/>} />
                <Route path='/blogs/:id' element={sessionStorage.getItem('token')?<BlogView/>:<Login/>} />
                <Route path='/gallery' element={sessionStorage.getItem('token')?<Gallery/>:<Login/>} />
                <Route path='/recipe' element={sessionStorage.getItem('token')?<Recipe/>:<Login/>} />
                <Route path='/recipe/:id' element={sessionStorage.getItem('token')?<RecipeView/>:<Login/>} />
                <Route path='/categories' element={sessionStorage.getItem('token')?<Categories/>:<Login/>} />
                <Route path='/categories/:category_id' element={sessionStorage.getItem('token')?<ViewCategories/>:<Login/>} />
                <Route path='/categories/:category_id/:sub_id' element={sessionStorage.getItem('token')?<SubCategory/>:<Login/>} />
                <Route path='/menu' element={sessionStorage.getItem('token')?<MasterMenu/>:<Login/>} />
                <Route path='/menu/:menu_id' element={sessionStorage.getItem('token')?<MasterView/>:<Login/>} />
                <Route path='/menu/:menu_id/:inner_id' element={sessionStorage.getItem('token')?<InnerView/>:<Login/>} />
                <Route path='/sms' element={sessionStorage.getItem('token')?<SmsSend/>:<Login/>} />
                <Route path='/profile' element={sessionStorage.getItem('token')?<Profile/>:<Login/>} />
                <Route path='/save' element={sessionStorage.getItem('token')?<Saved/>:<Login/>} />
                <Route path='/saved' element={sessionStorage.getItem('token')?<SavedMeals/>:<Login/>} />
                <Route path='*' element={<Login/>} />
          </Routes>
        </div>
    </div>  
</div>
</div>
</BrowserRouter>:<Login />     
  );
} 

export default App;