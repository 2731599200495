import React, { useState } from 'react'
import main_logo from '../Images/main.png'
import eye from '../Icons/eye.png'
import hide from '../Icons/hide.png'
import { isAlphanumeric, isEmpty } from 'validator'
import axios from 'axios'

export default function Login() {
  const [username, setUsername] = useState('')
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('') 
  const [formErrorMessage, setFormErrorMessage] = useState("")
  const [open, setOpen] = useState(false)

    // handle toggle 
    const toggle = () =>{
        setOpen(!open)
    }

  const handleSubmit = async(e) => {
    e.preventDefault()

    if (isEmpty(username) && isEmpty(password)) return setFormErrorMessage("Username va Password bo'sh bo'lmasligi kerak!")
    if (!isAlphanumeric(username)) return setFormErrorMessage("Username faqat harf va sonlardan iborat bo'lishi kerak!")
    if (!isAlphanumeric(password)) return setFormErrorMessage("Parol faqat harf va sonlardan iborat bo'lishi kerak!")

      setLoading(true)
      await axios.post(`${process.env.REACT_APP_SERVER_HOST}/auth/login/`, { username: username, password: password })
      .then((res) => {
        sessionStorage.setItem('token', res.data.access) 
        window.location.reload('/')
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
        console.log(err)
        setFormErrorMessage(err.code === "ERR_NETWORK" ? "Serverdan javob kelmadi.":err.response.data.detail)
      });      
  }

 
  return (
    <div>
        <div className="mt-16 flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
            <div>
            <img
              className="mx-auto h-20 w-auto"
              src={main_logo}
              alt="Your Company"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-yellow-800">
              ADMIN PANEL
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              <a href="http://www.favvora-urgench.uz/" className="font-medium text-indigo-600 hover:text-indigo-500">
                Favvora Restoran
              </a>
              ni uchun{' '}
            </p>
            </div>

            <h5 className='text-center mt-10 font-bold tracking-tight text-gray-900'>KIRISH</h5>

          <form onSubmit={handleSubmit} className="mt-8 space-y-6" method="POST">
            <input type="hidden" name="remember" defaultValue="true" />
            <section>
            <div className="mx-auto my-3 relative">
                  <div className='w-full'>
                      <input
                       onChange={(e) => { setUsername(e.target.value); setFormErrorMessage(null) }}
                       value={username}
                       type="text"
                       placeholder="Enter your username"
                       className="w-full h-14 rounded-lg appearance-none border px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"/>
                  </div>
              </div>


              <div className="mx-auto my-3 relative">
                  <div className='w-full'>
                      <input
                       onChange={(e) => { setPassword(e.target.value); setFormErrorMessage(null) }}
                       value={password}
                       type={(open === false)? 'password' :'text'}
                       placeholder="Enter your password"
                       className="w-full h-14 rounded-lg appearance-none border px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"/>
                  </div>

                  <div className='text-2xl absolute top-4 right-5'>
                  {
                    (open === false)?<img src={eye} width={20} height={20} alt="" onClick={toggle} />:
                    <img  src={hide} width={20} height={20} alt="" onClick={toggle} />
                  } 
                  </div>
              </div>
           <small className='text-red-500'>{formErrorMessage}</small>
          </section>


            <div>
              <button
                type="submit"
                className={`${loading?"pointer-events-none bg-gray-300":"bg-indigo-600"} group relative flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2`}
              >
                {loading?"Loading ...":"Login"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
