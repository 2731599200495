import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isSwitch: false
}


export const switched = createSlice({
    name: 'is_switch',
    initialState,
    reducers: {
        switchFunc: (state, action) => {
            state.isSwitch = !state.isSwitch
        }
    }
})


export const { switchFunc } = switched.actions

export default switched.reducer