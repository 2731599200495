import { configureStore } from '@reduxjs/toolkit'
import listSlice from '../features/menuRecipeTodo/listSlice'
import basicData from '../features/categories/basic'
import isShow from '../features/sitecontent/isShow'
import isSwitch from '../features/switch/switch'
import isSwitch2 from '../features/switch/switch2'
import sectionList from '../features/master/sectionmenu'
export const store = configureStore({
  reducer: {
     listSlice,
     basicData,
     isShow,
     isSwitch,
     isSwitch2,
     sectionList
  },
})