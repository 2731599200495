import { Link, useParams } from 'react-router-dom'
import { ArrowUturnLeftIcon, PlusIcon } from '@heroicons/react/24/solid'
import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { BarLoader } from 'react-spinners'
import axios from 'axios'
import Swal from 'sweetalert2'
import CatFood from './CatFood'


function MasterView() {
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [recipeLoading, setRecipeLoading] = useState(false)
  const [catLoading, setCatLoading] = useState(false)
  const [currentCat, setCurrentCat] = useState({})
  const [recipes, setRecipes] = useState([])
  const [sections, setSections] = useState([])
  const [open, setOpen] = useState(false)
  
  
  const [threeOpen, setThreeOpen] = useState(false)
  const cancelButtonRef = useRef(null)

  const [currentPosition, setCurrentPosition] = useState(null)
  const [fullCat, setFullCat] = useState([])
  const [fullSubCat, setFullSubCat] = useState([])


  const [one, setOne] = useState(null)
  const [two, setTwo] = useState(null)
  const [three, setThree] = useState(null)
  const [four, setFour] = useState(null)
  const [five, setFive] = useState(null)


    // State with list of all checked item
  const { menu_id } = useParams()

  const showAlert = (foo) => {
    Swal.fire({
        title: "Ogohlantirish!",
        text: foo,
        icon: "warning",
        confirmButtonText: "OK",
      }).then(function () {
        // Redirect the user
        // window.location.reload()
      });
}


const succesAlert = (title) => {
  Swal.fire({
    icon: 'success',
    title,
    showConfirmButton: false,
    timer: 1500
  })
}





  const fetchCat = async() => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/admin/menu/category/${menu_id}/`, {
        headers: {
          "Authorization": `Bearer ${sessionStorage.getItem('token')}`
        }
      })
  
      setCurrentCat(res.data)
  
    } catch (error) {
      console.log(error)
    }
  }


  const fetchFullCat = async() => {
    setCatLoading(true)
    await axios.get(`${process.env.REACT_APP_SERVER_HOST}/admin/menu/category/`, {
      headers: {
        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
      }
    }).then((res) => {
      setFullCat(res.data)
      const nedata = res.data.filter(item => item.parent === res.data.find(j => j.id === Number(menu_id)).parent)
      setFullSubCat(nedata)
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setCatLoading(false)
    }) 

  }



  const fetchSection = async() => {
    setLoading(true)
    await axios.get(`${process.env.REACT_APP_SERVER_HOST}/admin/menu/section/`, {
      headers: {
        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
      }
    }).then((res) => {
      const { data } = res
      setSections(data.filter(item => item.category === Number(menu_id)))
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })
  }

  

  const fetchRecipe = async() => {
    setRecipeLoading(true)
    await axios.get(`${process.env.REACT_APP_SERVER_HOST}/admin/menu/${menu_id}/meals/`, {
      headers: {
        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
      }
    }).then((res) => {
      setRecipes(res.data)  
      setCount(res.data.length)
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setRecipeLoading(false)
    })
  }


  useEffect(() => {
    fetchCat()
    fetchRecipe()
    fetchFullCat()
    fetchSection()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


    // Add/Remove checked item from list


    const handleCatSort = (item) => {      
      const data = fullCat.filter(k => k.parent === item)
      setFullSubCat(data)
    }

    
    
    const handleSubmit = (item) => {
      setCount(count => count - 1)
      setOpen(false)
      switch (currentPosition) {
        case 1:
          setOne(item); 
          recipes.splice(recipes.indexOf(item), 1) 
          break;
          case 2:
          setTwo(item);
          recipes.splice(recipes.indexOf(item), 1)
          break;
        case 3:
          setThree(item);
          recipes.splice(recipes.indexOf(item), 1)
          break;
          case 4:
          setFour(item);
          recipes.splice(recipes.indexOf(item), 1)
          break;
        case 5:
          setFive(item);
          recipes.splice(recipes.indexOf(item), 1)
          break;
          default: 
      }         
    }


    const handleCheck = (item) => {      
      if (item === 2 && !one) return showAlert("Birinchi katakni to'ldiring!")
      if (item === 3 && !two) return showAlert("Bundan oldingi to'ldiring!")
      if (item === 4 && !three) return showAlert("Bundan oldingi katakni to'ldiring!")
      if (item === 5 && !four) return showAlert("Bundan oldingi katakni to'ldiring!")
      setCurrentPosition(item); 
      setOpen(true)  
    }


    const handleClearData = () => {
      setOne(null)
      setTwo(null)
      setThree(null)
      setFour(null)
      setFive(null)

      fetchRecipe()
    }
    
    
    const handleTwoSubmit = async() => {
      if (!one) return showAlert("Kamida 1 ta joy to'ldirilishi shart!")

      
      const data_positions = `{ 
        ${one ? `"${one.id}": ${1} ${two ? ",":""}`: ""} 
        ${two ? `"${two.id}": ${2} ${three ? ",":""}`: ""} 
        ${three ? `"${three.id}": ${3} ${four ? ",":""}`: ""} 
        ${four ? `"${four.id}": ${4} ${five ? ",":""} `: ""} 
        ${five ? `"${five.id}": ${5} `: ""} 
      }`
      

      const one_data_positions = `{ 
        ${one ? `"${one.id}": ${1}`: ""}
      }`
      const newjson = JSON.parse(one_data_positions)

      const one_meal_id = [one.id]
      
      let mealsid = [one ? one.id : null, two ? two.id : null, three ? three.id : null, four ? four.id : null, five ? five.id : null].filter(n=>n)
      const new_data = JSON.parse(data_positions)




      if (one && !two && !three && !four && !five) return (
         axios({
          method: "post",
          url: `${process.env.REACT_APP_SERVER_HOST}/admin/menu/section/`,
          data: {
            meals_position: newjson,
            main_meal: one.id,
            category: Number(menu_id),
            meals: one_meal_id
          }, 
          headers: {
                "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then((res) => {
          setLoading(false)
          if (res.statusText === "Created") 
          fetchRecipe() 
          fetchSection()
          window.location.reload()          
          
        }).catch((error) => {
          console.log(error)
          setLoading(false)
        })
      )




      setLoading(true)
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_HOST}/admin/menu/section/`,
        data: {
          meals_position: new_data,
          main_meal: one.id,
          category: Number(menu_id),
          meals: mealsid
        }, 
        headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
          }
      }).then((res) => {
        setLoading(false)
        if (res.statusText === "Created") {
           succesAlert("Muvaffaqqiyatli yaratildi!")
           window.location.reload()
        }
        // setOpen(false)
        // setThreeOpen(false)
        // setOpen(false)
        // setCurrentPosition(null)
        // setOne(null)
        // setTwo(null)
        // setThree(null)
        // setFour(null)
        // setFive(null)
        // fetchRecipe()
        // fetchSection()
        
        
      }).catch((error) => {
        console.log(error)
        setLoading(false)
      })

    }



    const handleThreeOpen = () => {
      if ( sections.length !== 0 && sections[sections.length - 1].meals.length < 5) return showAlert("Tepadagi sectionni to'ldiring!")
      setThreeOpen(true)
    }



  return (
    <div className="flex flex-col justify-center h-full items-center">
      <div className="flex float-left bg-slate-50 py-2 flex-row w-full justify-center items-center">
        <div className="flex-none float-left">
          <Link to="/menu" className="p-2 flex flex-row bg-blue-100 border border-gray-500 m-1 rounded-md hover:text-gray-500 active:bg-blue-300"> <span className="pr-1"> <ArrowUturnLeftIcon width={25} height={25} />  </span> Orqaga</Link>
        </div>
        <div className="flex-1 w-full text-center">
          Menu tuzish
        </div>
      </div>

      { catLoading ? <div className="flex flex-col w-full my-5 justify-start items-start h-[10vh]">
            <BarLoader color="#36d7b7" />
            <h6 className="my-5 text-gray-500">
              Ma'lumotlar yuklanmoqda ...
            </h6>
        </div> : <div className="w-full flex flex-col p-2 my-2 text-left">
       <div className="my-1">
        {fullCat.filter(a => a.parent === null && a.position !== 1).map(item => (
            <button key={item.id} onClick={() => handleCatSort(item.id)} className={`w-full justify-center rounded-md active:bg-blue-400 focus:bg-blue-400 border border-blue-600 border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm  hover:bg-blue-700 focus:outline-none ${ fullCat.find(b => b.id === Number(menu_id)).parent === item.id ? "ring-2 ring-blue-500 ring-offset-2" : ""} sm:ml-3 sm:w-auto sm:text-sm`}> {item.title} </button>
          ))} 
        <a href="/save" className={`w-full justify-center rounded-md border border-yellow-600 border-transparent bg-yellow-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}> 
            Tanlanganlar
        </a>

       </div>

      <div className="my-2">
        {fullSubCat.map(item => (
            <a key={item.id} type="button" href={`/menu/${item.id}`} className={`w-full justify-center rounded-md border border-slate-600 border-transparent bg-slate-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 ${item.id === Number(menu_id) ? "ring-2 ring-slate-500 ring-offset-2" : ""} sm:ml-3 sm:w-auto sm:text-sm`}> {item.title} </a>
          ))} 
      </div> 
      </div> } 

      <div className="w-full h-full overflow-y-auto border">
        <CatFood reloading={loading} />
      </div>

      <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">

                  <div className="w-full text-center my-2"> <h5>{ `${currentCat.title} (${count})` }  </h5> </div>
                  <div className="my-2 px-2 w-full shadow-md overflow-y-auto h-96">

                    { recipes.map((item, index) => (
                      <div onClick={() => handleSubmit(item)} key={index} className="flex p-1 hover:bg-blue-400 cursor-pointer rounded-sm border-y flex-row justify-start items-center">
                          <div className="flex px-2 w-1/12 text-xs flex-col justify-start items-start">
                            {index + 1}
                          </div>
                          <div className="flex flex-col w-2.5/12">
                            <img className="rounded-full object-cover w-10 h-10" src={item.thumbnail} alt="" />
                          </div>

                          <div className="flex px-2 w-8/12 flex-col justify-start items-start">
                            <div className="flex  text-sm flex-col justify-center items-center">
                              {item.title}
                            </div>
                            <div className="flex text-xs flex-col justify-center items-center">
                              {item.summa} so'm
                            </div>
                          </div>
                      </div>
                    )) }

                  </div>
                  <div>
                  
                  </div>



               
                    
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Orqaga
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>



    <Transition.Root show={threeOpen} as={Fragment}>
      <Dialog as="div" className="relative w-1/3 z-10" initialFocus={cancelButtonRef} onClose={setThreeOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed w-full flex flex-col justify-center items-center inset-0 z-10 overflow-y-auto">
          <div className="flex w-1/3 min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="transform px-5 w-full overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all">
                      <div className="text-center mx-auto my-5">
                        <h3>Section qo'shish</h3>
                      </div>
                      <div className="grid rounded-md p-3 grid-cols-3 gap-2 w-full">
                        <div className={`col-span-2 rounded-md flex flex-col justify-center items-center border-slate-400 border h-36`}>
                          { !one ? <button onClick={() => handleCheck(1)} className="inline-flex w-full justify-center rounded-md border border-transparent bg-white border-gray-400 px-4 py-4 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">
                            <PlusIcon className="w-5 text-gray-300 h-5" />
                          </button> : '' } 
                          { one ? <div className="group object-cover relative w-full h-full rounded-md bg-gray-900" >
                            <img className="absolute inset-0 rounded-md h-full w-full object-cover group-hover:opacity-50" src={one.thumbnail} alt="" />
                            <div className="relative flex flex-col justify-center items-center">
                                <div className="translate-y-8 mt-7 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100">
                                    <h5 className="text-white text-sm">{one.title}</h5>
                                    <h5 className="text-white text-xs">{one.summa} so'm</h5>
                                </div>
                            </div>
                          </div> 
                          : "" }
                        </div>
                        <div className={`border rounded-md flex flex-col justify-center items-center border-slate-400 h-36`}>
                          { !two ? <button onClick={() => handleCheck(2)} className="inline-flex w-full justify-center rounded-md border border-transparent bg-white border-gray-400 px-4 py-4 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">
                            <PlusIcon className="w-5 text-gray-300 h-5" />
                          </button> : '' }
                          { two ? <div className="group object-cover relative w-full h-full rounded-md bg-gray-900" >
                            <img className="absolute inset-0 rounded-md h-full w-full object-cover group-hover:opacity-50" src={two.thumbnail} alt="" />
                            <div className="relative flex flex-col justify-center items-center">
                                <div className="translate-y-8 mt-7 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100">
                                    <h5 className="text-white text-sm">{two.title}</h5>
                                    <h5 className="text-white text-xs">{two.summa} so'm</h5>
                                </div>
                            </div>
                          </div>  : "" }
                        </div>
                        <div className="border rounded-md flex flex-col justify-center items-center border-slate-400 h-36">
                        { !three ? <button onClick={() => handleCheck(3)} className="inline-flex w-full justify-center rounded-md border border-transparent bg-white border-gray-400 px-4 py-4 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">
                            <PlusIcon className="w-5 text-gray-300 h-5" />
                          </button> : '' }
                          { three ? <div className="group object-cover relative w-full h-full rounded-md bg-gray-900" >
                            <img className="absolute inset-0 rounded-md h-full w-full object-cover group-hover:opacity-50" src={three.thumbnail} alt="" />
                            <div className="relative flex flex-col justify-center items-center">
                                <div className="translate-y-8 mt-7 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100">
                                    <h5 className="text-white text-sm">{three.title}</h5>
                                    <h5 className="text-white text-xs">{three.summa} so'm</h5>
                                </div>
                            </div>
                          </div>  : "" }
                        </div>
                        <div className="border rounded-md flex flex-col justify-center items-center border-slate-400 h-36">
                          { !four ? <button onClick={() => handleCheck(4)} className="inline-flex w-full justify-center rounded-md border border-transparent bg-white border-gray-400 px-4 py-4 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">
                            <PlusIcon className="w-5 text-gray-300 h-5" />
                          </button> : '' }
                          { four ? <div className="group object-cover relative w-full h-full rounded-md bg-gray-900" >
                            <img className="absolute inset-0 rounded-md h-full w-full object-cover group-hover:opacity-50" src={four.thumbnail} alt="" />
                            <div className="relative flex flex-col justify-center items-center">
                                <div className="translate-y-8 mt-7 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100">
                                    <h5 className="text-white text-sm">{four.title}</h5>
                                    <h5 className="text-white text-xs">{four.summa} so'm</h5>
                                </div>
                            </div>
                          </div>  : "" }
                        </div>
                        <div className="border rounded-md flex flex-col justify-center items-center border-slate-400 h-36">
                          { !five ? <button onClick={() => handleCheck(5)} className="inline-flex w-full justify-center rounded-md border border-transparent bg-white border-gray-400 px-4 py-4 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">
                            <PlusIcon className="w-5 text-gray-300 h-5" />
                          </button> : '' }
                          { five ? <div className="group object-cover relative w-full h-full rounded-md bg-gray-900" >
                            <img className="absolute inset-0 rounded-md h-full w-full object-cover group-hover:opacity-50" src={five.thumbnail} alt="" />
                            <div className="relative flex flex-col justify-center items-center">
                                <div className="translate-y-8 mt-7 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100">
                                    <h5 className="text-white text-sm">{five.title}</h5>
                                    <h5 className="text-white text-xs">{five.summa} so'm</h5>
                                </div>
                            </div>
                          </div>  : "" }
                        </div>
                      </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleTwoSubmit}
                  >
                    Qo'shish
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleClearData}
                  >
                    Tozalash
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setThreeOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>














    


      <div className="w-full text-center my-2">
        <button onClick={handleThreeOpen} className="rounded-md my-1 border p-2 active:shadow-none transition-shadow hover:shadow-md">QO'SHISH</button>
      </div>
    </div>
  )
}

export default MasterView