import axios from 'axios'
import Swal from 'sweetalert2'
import { useEffect, useState, useRef, Fragment } from 'react'
import { isLength } from "validator";
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { CalendarIcon, ArrowPathIcon } from '@heroicons/react/24/solid'
import { Dialog, Transition } from "@headlessui/react";
import { NavLink, useParams, useNavigate } from 'react-router-dom'
import moment from 'moment' 


  


function RecipeView() {

      //category


    const { id } = useParams()
    const [data, setData] = useState({})
    const [cat, setCat] = useState({})
    const navigate = useNavigate()


    //category

  const [loading, setLoading] = useState(false)


  const [imgSize, setImgSize] = useState(null)
  const [imgHeight, setImgHeight] = useState(null)
  const [imgWidth, setImgWidth] = useState(null)
  const [rasm,setRasm] = useState(false)


  const [deleteModal, setdeleteModal] = useState(false)
  const cancelDeleteModal = useRef(null)

  const [titleEditError, setEditError] = useState("");
  const [editSummaError, setEditSummaError] = useState("")
  const [editTextError, setEditTextError] = useState("");
  const [editImageError, setEditImageError] = useState("");


  const [viewData, setWiewData] = useState({})
  const [editModal, setEditModal] = useState(false);
  const [editView, setViewModal] = useState(null)
  const [editOffer, setEditOffer] = useState("");
  const [editSumma, setEditSumma] = useState(null)
  const [editText, setEditText] = useState("");
  const [selectEdit, setSelect] = useState(null);

  const handleShowModal = async(item) => {
    setWiewData(item); 
    setEditModal(true)
    setEditSumma(item.summa)
    setEditText(item.text)
    setEditOffer(item.title)
    const response = await fetch(item.image, { mode: 'no-cors' });
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File([blob], 'image.jpg', {type: blob.type});
    setSelect(file)

  }


  const handleEditFileSelect = (e) => {
    setRasm(true)
    var _URL = window.URL || window.webkitURL;
    var objectUrl = _URL.createObjectURL(e.target.files[0]);
    const img = new Image()  

      img.onload = function () {
        setImgHeight(this.height)
        setImgWidth(this.width)
      };
      img.src = objectUrl

    setImgSize((e.target.files[0].size / 1024).toFixed(2))
    setSelect(e.target.files[0]) 
    setViewModal(URL.createObjectURL(e.target.files[0]))
    setEditImageError(null)
  }
 
  const cancelEdit = useRef(null);





    const [category, setCategory] = useState([null])


    const fetchData = async() => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/admin/menu/${id}/`, {
              headers: {
                "Authorization": `Bearer ${sessionStorage.getItem('token')}`
              }
            })

            const cats = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/admin/menu/category/`, {
                headers: {
                  "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
              })
            
            setData(res.data)

            try {
                await axios.get(`${process.env.REACT_APP_SERVER_HOST}/admin/menu/category/${res.data.category}/`, {
                    headers: {
                        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                      }
                }).then((res) => {
                    setCategory(res.data)
                    const sub_cat = cats.data.find((item) => item.id === res.data.parent)
                    setCat(sub_cat)
                })
            } catch (error) {
                console.log(error)
            }


          } catch (error) {
            console.log(error)
        }

       
    }

    const showAlert = () => {
        Swal.fire({
            title: "Muvaffaqqiyatli",
            text: "Malumot o'chirildi",
            icon: "success",
            confirmButtonText: "OK",
          }).then(function () {
            // Redirect the user
            // window.location.reload()
          });;
    }

    useEffect(() => {
        fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  





 //edit data
 const handleEditSubmit = async(e) => {
    
    e.preventDefault();


      if (!isLength(editOffer, { min: 2, max: 50 }))
        return setEditError(
          "Satr uzunligi 2 va 50 oralig'ida bo'lishi kerak"
        );

      if (!isLength(editText, { min: 5, max: 2000 }))
        return setEditTextError(
          "Satr uzunligi 5 va 200 oralig'ida bo'lishi kerak"
        );

        if (editSumma < 1000) return setEditSummaError("Bahosi kamida 1000 so'm bolishi shart!")

        if (imgSize < 100 && rasm === true) return setEditImageError("Rasm hajmi kamida 100 KB bo'lishi kerak!")
        if ((imgHeight < 1500 || imgWidth < 1500) && rasm === true) return setEditImageError("Rasm o'lchamlari 1500x1500px bo'lishi kerak!") 
      

      var bodyFormData = new FormData();
      bodyFormData.append("title", editOffer)
      bodyFormData.append("text", editText)
      bodyFormData.append("summa", editSumma)

      var bodyFormData2 = new FormData();
      bodyFormData2.append("title", editOffer) 
      bodyFormData2.append("text", editText)
      bodyFormData2.append("summa", editSumma)
      bodyFormData2.append("image", selectEdit)

      setLoading(true)
      axios({
        method: "put",
        url: `${process.env.REACT_APP_SERVER_HOST}/admin/menu/${viewData.id}/`,
        data: rasm ? bodyFormData2 : bodyFormData,
        headers: {
              "content-type": "multipart/form-data",
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
      }).then((response) => {
          setLoading(false)
          setEditModal(false)
          showAlert("Ma'lumot o'zgartirildi!")
          fetchData()
        }).catch(function (res) {
            setLoading(false)
          setEditSummaError(res.response.data.summa)
          setEditImageError(res.response.data.image[0]);
        });

        setRasm(false)
  };







    //delete
  const handleDeleteData = async(e) => {

    setLoading(true)
    await axios.delete(`${process.env.REACT_APP_SERVER_HOST}/admin/menu/${data.id}/`, {
      headers: {
            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
          }      
    }).then(function (response) {
        setLoading(false)
        setdeleteModal(false)
        showAlert("Ma'lumot o'chirildi!")
        navigate('/recipe')
      }).catch(function (response) {
          setLoading(false)
        console.log(response);
      });
  }


    return (
        <div className="flex flex-col">
            <div className="grid mt-10 mb-2 grid-cols-1 gap-1">
                <div className="ml-5 xl:mx-10 sm:mx-5 text-xl font-bold float-left">
                    <NavLink className="hover:text-blue-900 hover:underline" to={"/recipe"}>Taomlar menusi</NavLink><span className='text-sm'>{" >> "} { data.title }</span>
                </div>
            </div>

            <div className="p-1.5 w-full inline-block align-middle">
            <Transition.Root show={editModal} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative"
                    initialFocus={cancelEdit}
                    onClose={setEditModal}
                >
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300" 
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300" 
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                        <form
                        onSubmit={handleEditSubmit}
                        className="mt-8 space-y-6"
                        method="POST"
                        >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg my-2 font-medium leading-6 text-yellow-900"
                                >
                                    Menu Tahrirlash
                                </Dialog.Title>
                                    <section>
                                        <div className="mt-5 border md:col-span-2 md:mt-0">
                                            <div className="shadow sm:overflow-hidden sm:rounded-md">
                                                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                                                <div className="grid grid-cols-3 gap-6">
                                                    <div className="col-span-6 sm:col-span-6">
                                                    <label
                                                        htmlFor="company-website"
                                                        className="block text-left text-sm font-medium text-gray-700"
                                                    >
                                                        Nomi
                                                    </label>
                                                    <div className="mt-1 flex rounded-md shadow-sm">

                                                        <input 
                                                        type="text"
                                                        name="title"
                                                        minLength={2}
                                                        maxLength={50}
                                                        onChange={(e) => { setEditOffer(e.target.value); setEditError(null) }} 
                                                        value={editOffer}
                                                        id="company-website"
                                                        className="block rounded-lg appearance-none border px-3 py-2 w-full flex-1 rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                        placeholder="Taom nomi"
                                                        />

                                                    </div>
                                                        <small className="text-red-500">{''}{titleEditError}</small>
                                                    </div>
                                                </div>

                                                

                                                <div className="grid grid-cols-3 gap-6">
                                                    <div className="col-span-6 sm:col-span-6">
                                                    <label
                                                        htmlFor="company-website"
                                                        className="block text-left text-sm font-medium text-gray-700"
                                                    >
                                                        Bahosi
                                                    </label>
                                                    <div className="mt-1 flex rounded-md shadow-sm">

                                                        <input 
                                                            type="number"
                                                            name="title"
                                                            min={1000}
                                                            onChange={(e) => { setEditSumma(e.target.value); setEditSummaError(null) }} 
                                                            value={editSumma}
                                                            id="company-website"
                                                            className="block rounded-lg appearance-none border px-3 py-2 w-full flex-1 rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                            placeholder="Taom nomi"
                                                            required
                                                            />

                                                    </div>
                                                        <small className="float-left m-1 text-red-500">{editSummaError}{''}</small>
                                                    </div>
                                                </div>

                                                <div>
                                                    <label
                                                    htmlFor="about"
                                                    className="block text-left text-sm font-medium text-gray-700"
                                                    >
                                                    Izoh
                                                    </label>
                                                    <div className="mt-1">
                                                    <textarea
                                                        id="about"
                                                        name="text"
                                                        minLength={5}
                                                        maxLength={2000}
                                                        onChange={(e) => { setEditText(e.target.value); setEditTextError(null) }}
                                                        rows={3}
                                                        className="mt-1 p-1 border block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                        placeholder="Text your here ..."
                                                        defaultValue={editText?editText:viewData.text}
                                                    />
                                                    </div>
                                                    <p className="mt-2 text-sm text-left text-red-500">
                                                    {editTextError}
                                                    </p>
                                                </div>
                                                <div>
                                                    <div className="flex justify-start w-full"> 
                                                        <div className="mb-3">
                                                        <label className="text-left block text-sm font-medium text-gray-700">
                                                        <div className="float-right text-xs">                                                
                                                            {imgSize ? <span className={`text-xs mx-1 px-2 ${imgSize > 100 ? "bg-green-300 text-green-800" : "bg-red-300 text-red-800"} rounded-full`}>
                                                                {imgSize + " KB"}
                                                            </span> : <span className="text-xs text-gray-800 rounded-full">Hajmi kamida: 100KB</span> } 
                                                            </div>                                                
                                                            <div>                                                
                                                            {imgWidth && imgHeight ? <span className={`text-xs px-2 ${imgHeight > 1500 && imgWidth > 1500 ? "bg-green-300 text-green-800" : "bg-red-300 text-red-800"} rounded-full`}>
                                                                {imgWidth + "px / " + imgHeight + "px"}
                                                            </span> : <span className="text-xs">RASM </span> }
                                                            </div> 
                                                        </label>                                                            
                                                            <input className="form-control mx-1 justify-center  w-full px-2 py-1.5 text-base font-normaltext-gray-700bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                            type="file" name="file" onChange={handleEditFileSelect} id="formFile"/> 
                                                        </div>
                                                        </div>
                                                        

                                                    <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                                    <img className="object-cover w-96 h-56" src={editView?editView:viewData.image} alt=""/><div className="space-y-1 text-center">
                                                        <div className="flex flex-col justify-center text-sm text-gray-600">
                                                        
                                                        </div>
                                                    </div>

                                                    </div>
                                                    <p className="m-1 text-sm text-red-500">{editImageError}</p>
                                                </div>
                                                </div>
                                                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">

                                                <button
                                                onClick={() => setEditModal(false)} 
                                                    type="button"
                                                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                                >
                                                Orqaga
                                                </button>

                                                <button
                                                type="submit"
                                                className={`${loading ? "pointer-events-none bg-gray-400" : "bg-blue-600"} mt-2 inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                                                >
                                                {loading ? "Loading..." : "Saqlash"}
                                                </button>
                                                </div>
                                            </div>

                                        </div>
                                    </section>
                                </div>
                            </div>
                            </div>
                        </Dialog.Panel>
                        </form>
                        </Transition.Child>
                    </div>
                    </div>
                </Dialog>
                </Transition.Root>





                <Transition.Root show={deleteModal} as={Fragment}>
                        <Dialog as="div" className="relative z-10" initialFocus={cancelDeleteModal} onClose={setdeleteModal}>
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>




                        <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                        Mahsulotni o'chirish
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Haqiqatdan ham mahsulotni o'chirmoqchimisiz?
                                        </p>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button
                                    type="button"
                                    className={`${loading ? "pointer-events-none bg-gray-400" : "bg-red-600"} inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                                    onClick={handleDeleteData}
                                >
                                    {loading ? "Loading..." : "O'chirish"} 
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => setdeleteModal(false)}
                                    ref={cancelDeleteModal}
                                >
                                    Cancel
                                </button>
                                </div>
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                        </div>
                    </Dialog>
                    </Transition.Root>






                <div className="py-6 px-4 sm:p-6 md:py-10 md:px-8">
                    <div className="max-w-4xl mx-auto grid grid-cols-1 lg:max-w-5xl lg:gap-x-20 lg:grid-cols-2">
                        <div className="relative p-3 col-start-1 row-start-1 flex flex-col-reverse rounded-lg bg-gradient-to-t from-black/75 via-black/0 sm:bg-none sm:row-start-2 sm:p-0 lg:row-start-1">
                            <h1 className="mt-1 text-lg font-semibold text-white sm:text-slate-900 md:text-2xl dark:sm:text-white">
                                {data.title}
                            </h1>
                            <p className="text-sm leading-4 my-3 font-bold text-white sm:text-slate-500 dark:sm:text-slate-400">
                                {data.summa} so'm
                            </p>
                            <p className="text-sm leading-4 font-medium text-white sm:text-slate-500 dark:sm:text-slate-400">
                                {cat.title} &#9656;
                                {category.title}
                            </p>
                        </div>
                        <div className="grid gap-4 col-start-1 col-end-3 row-start-1 sm:mb-6 sm:grid-cols-4 lg:gap-6 lg:col-start-2 lg:row-end-6 lg:row-span-6 lg:mb-0">
                            <img src={data.image} alt="" className="w-full border-2 border-gray-300 h-full object-cover rounded-lg sm:h-full sm:col-span-2 lg:col-span-full" loading="lazy" />
                        </div>

                        <dl className="mt-4 text-xs font-medium flex items-center row-start-2 sm:mt-1 sm:row-start-3 md:mt-2.5 lg:row-start-2">
                            <dt className="sr-only">Location</dt>
                            <dd className="flex items-center">
                                <CalendarIcon className="w-5 h-5 mr-2" />
                                { moment(data.created_at).format('DD/MM/YYYY hh:mm') }
                            </dd>

                            <dd className="flex items-center">
                                <ArrowPathIcon className="w-5 h-5 mx-2" />
                                { moment(data.updated_at).format('DD/MM/YYYY hh:mm') }
                            </dd>
                        </dl>
                        <div className="mt-4 col-start-1 row-start-3 self-center sm:mt-0 sm:col-start-2 sm:row-start-2 sm:row-span-2 lg:mt-6 lg:col-start-1 lg:row-start-3 lg:row-end-4">
                            <button onClick={() => handleShowModal(data)} type="button" className="mx-1 bg-blue-600 text-white text-sm leading-6 font-medium py-2 px-3 rounded-lg">
                                Tahrirlash
                            </button>

                            <button onClick={() => setdeleteModal(true)} type="button" className="mx-1 bg-red-500 text-white text-sm leading-6 font-medium py-2 px-3 rounded-lg">
                                O'chirish
                            </button>
                        </div>
                        <p className="mt-4 text-sm leading-6 col-start-1 sm:col-span-2 lg:mt-6 lg:row-start-4 lg:col-span-1 dark:text-slate-400">
                            {data.text}
                        </p>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecipeView