import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isShow: true
}


export const show = createSlice({
    name: 'is_show',
    initialState,
    reducers: {
        showed: (state, action) => {
            state.isShow = !state.isShow
        }
    }
})


export const { showed } = show.actions

export default show.reducer