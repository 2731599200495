import axios from 'axios';
import Swal from 'sweetalert2'
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { EyeIcon, TrashIcon, PencilIcon } from '@heroicons/react/24/solid'
import React, { Fragment, useEffect, useRef, useState } from 'react';
import moment from 'moment';

function Gallery() {
  const [selectfile, setSelectFile] = useState(null); 
  const [loading, setLoading] = useState(false) 

  const [imageError, setImageError] = useState("")
  const [images, setImages] = useState([]);

  const [imgSize, setImgSize] = useState(null)
  const [imgHeight, setImgHeight] = useState(null)
  const [imgWidth, setImgWidth] = useState(null)
  const [rasm, setRasm] = useState(false)

  const [viewData, setWiewData] = useState({})
  const [editModal, setEditModal] = useState(false);
  const [selectEdit, setSelect] = useState(null);
  const [editImageError, setEditImageError] = useState("");
  const [editView, setViewModal] = useState(null)

  const [deleteModal, setdeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)

  //delete
  const handleDeleteData = async(e) => {
    setLoading(true)
    await axios.delete(`${process.env.REACT_APP_SERVER_HOST}/admin/home/gallery/${deleteId}/`, {
      headers: {
            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
          }      
    }).then(function (response) {
        setLoading(false)
        setdeleteModal(false)
        showAlert("Ma'lumot o'chirildi!")
      }).catch(function (response) {
        setLoading(false)
        console.log(response); 
      });
  }

  //edit data
  const handleEditSubmit = async(e) => {
      e.preventDefault();      
      var bodyFormData = new FormData();
      bodyFormData.append("image", selectEdit)


      if (imgSize < 100 && rasm === true) return setEditImageError("Rasm hajmi minimum 100 KB bo'lishi kerak")
      if ((imgHeight < 580 || imgWidth < 720) && rasm === true) return setEditImageError("Rasm o'lchamlari 720x580 bo'lishi kerak!")

      setLoading(true)
      if (rasm) {
        await axios({
          method: "patch",
          url: `${process.env.REACT_APP_SERVER_HOST}/admin/home/gallery/${viewData.id}/`,
          data: bodyFormData,
          headers: {
                "content-type": "multipart/form-data",
                "Authorization": `Bearer ${sessionStorage.getItem('token')}`
              }
        }).then((response) => {
            setEditModal(false)
            showAlert("Ma'lumot o'zgartirildi!")
          }).catch(function (res) {
            setEditImageError(res.response.data.image[0]);
          }).finally(() => {
            setLoading(false)
          })
      } else {
        setEditModal(false)
        setLoading(false)
      }

        setRasm(false)
  };

  const handleShowModal = async(item) => {
    setViewModal(false)
    setWiewData(item); 
    setEditModal(true)

    const response = await fetch(item.image, { mode: 'no-cors' });
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File([blob], 'image.jpg', {type: blob.type}); 
    setSelect(file)

  }


  const cancelDeleteModal = useRef(null)

  const fetchData = async() => {
      setLoading(true)

      await axios.get(`${process.env.REACT_APP_SERVER_HOST}/admin/home/gallery/`, {
        headers: {
          "Authorization": `Bearer ${sessionStorage.getItem('token')}`
        }  
      })
      .then((res) => {
        setLoading(false)
        setImages(res.data)
      }).catch(err => {
        setLoading(false)
        console.log(err)
      });

     
  }

  useEffect(() => {
      fetchData()
  }, []);


  const handleFileSelect = (e) => {
    setRasm(true)
    var _URL = window.URL || window.webkitURL;
    setSelectFile(e.target.files[0]) 
    var objectUrl = _URL.createObjectURL(e.target.files[0]);
    const img = new Image()  

      img.onload = function () {
        setImgHeight(this.height)
        setImgWidth(this.width)
      };
      img.src = objectUrl

    setImgSize((e.target.files[0].size / 1024).toFixed(2))
    setSelectFile(e.target.files[0]) 
    setImageError(null)
  }

  const showAlert = (foo) => {
    Swal.fire({
        title: "Muvaffaqqiyatli",
        text: foo,
        icon: "success",
        confirmButtonText: "OK",
      }).then(function () {
        fetchData()
      });;
}


const handleEditFileSelect = (e) => {
  setRasm(true)
  var _URL = window.URL || window.webkitURL;
  setSelectFile(e.target.files[0]) 
  var objectUrl = _URL.createObjectURL(e.target.files[0]);
  const img = new Image()  

    img.onload = function () {
      setImgHeight(this.height)
      setImgWidth(this.width)
    };
    img.src = objectUrl


  setImgSize((e.target.files[0].size / 1024).toFixed(2))
  setSelect(e.target.files[0]) 
  setViewModal(URL.createObjectURL(e.target.files[0]))
  setEditImageError(null)
}

const cancelEdit = useRef(null);


  const handleSubmit = async(e) => {
      e.preventDefault();      
      var bodyFormData = new FormData();
      bodyFormData.append("image", selectfile)

      if (imgSize < 100) return setImageError("Rasm hajmi minimum 100 KB bo'lishi kerak")
      if (imgHeight < 580 || imgWidth < 720) return setImageError("Rasm o'lchamlari 720x580 bo'lishi kerak!")
      
      setLoading(true)
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_HOST}/admin/home/gallery/`,
        data: bodyFormData,
        headers: {
              "content-type": "multipart/form-data",
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
      }).then((response) => {
          setLoading(false)
          showAlert("Ma'lumot qo'shildi") 
        }).catch((res) => {
          setLoading(false)
          setImageError(res.response.data.image[0]);
        });

        setRasm(false)
  };

  
  return (
    <div className="flex flex-col justify-center">
      <div className="grid mt-10 mb-2 grid-cols-2 gap-2">
        <div className="mx-10 text-xl font-bold float-left">
            Galeriya bo'limi
        </div>
      </div>
     

      <div className="overflow-x-auto">
      <Transition.Root show={editModal} as={Fragment}>
          <Dialog
            as="div"
            className="relative"
            initialFocus={cancelEdit}
            onClose={setEditModal}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300" 
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300" 
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <form
                  onSubmit={handleEditSubmit}
                  className="mt-8 space-y-6"
                  method="POST"
                  >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg my-2 font-medium leading-6 text-yellow-900"
                          >
                            Galleriya Tahrirlash
                          </Dialog.Title>
                            <section>
                                  <div className="mt-5 border md:col-span-2 md:mt-0">
                                      <div className="shadow sm:overflow-hidden sm:rounded-md">
                                        <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                                          <div className="grid grid-cols-3 gap-6">
                                            <div className="col-span-6 sm:col-span-6">
                                              <div className="mt-1 flex rounded-md shadow-sm">

                                               

                                              </div>
                                            </div> 
                                          </div>

                                          <div>
                                            <div className="mt-1">
                                              
                                            </div>
                                          </div>
                                          <div>
                                            <div className="flex justify-start w-full"> 
                                                  <div className="mb-3">
                                                    <label className="text-left block text-sm font-medium text-gray-700">
                                                      <div className="float-right text-xs">                                                
                                                          {imgSize ? <span className={`text-xs mx-1 px-2 ${imgSize > 100 ? "bg-green-300 text-green-800" : "bg-red-300 text-red-800"} rounded-full`}>
                                                            {imgSize + " KB"}
                                                          </span> : <span className="text-xs text-gray-800 rounded-full">Hajmi kamida: 100KB</span> } 
                                                        </div>                                                
                                                        <div>                                                
                                                          {imgWidth && imgHeight ? <span className={`text-xs px-2 ${imgHeight > 580 && imgWidth > 580 ? "bg-green-300 text-green-800" : "bg-red-300 text-red-800"} rounded-full`}>
                                                            {imgWidth + "px / " + imgHeight + "px"}
                                                          </span> : <span className="text-xs">RASM </span> }
                                                        </div>
                                                    </label>  
                                                    <input className="form-control mx-1 justify-center  w-full px-2 py-1.5 text-base font-normaltext-gray-700bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                     type="file" name="file" onChange={handleEditFileSelect} id="formFile"/> 
                                                  </div>
                                                </div>
                                                

                                            <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                              <img className="object-cover w-96 h-56" src={editView?editView:viewData.image} alt=""/><div className="space-y-1 text-center">
                                                <div className="flex flex-col justify-center text-sm text-gray-600">
                                                
                                                </div>
                                              </div>

                                            </div>
                                              <p className="m-1 text-sm text-red-500">{editImageError}</p>
                                          </div>
                                        </div>
                                        <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">

                                        <button
                                          onClick={() => setEditModal(false)} 
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                          >
                                          Orqaga
                                        </button>

                                        <button
                                          type="submit"
                                          className={`mt-2 ${loading?"pointer-events-none bg-gray-400":"bg-blue-600"} inline-flex w-full justify-center rounded-md border border-transparent  px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                                        >
                                          {loading?"Loading...":"Saqlash"}
                                        </button>
                                        </div>
                                      </div>

                                  </div>
                            </section>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </form>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        </div>






      <Transition.Root show={deleteModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelDeleteModal} onClose={setdeleteModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>



        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Rasmni o'chirish
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Haqiqatdan ham shu rasmni o'chirib tashlamoqchimisiz. Keyin qayta tiklay olmaysiz?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className={`${loading ? "pointer-events-none bg-gray-400" : "bg-red-600"} inline-flex w-full justify-center rounded-md border border-transparent  px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                    onClick={handleDeleteData}
                  >
                    {loading ? "Loading..." : "O'chirish"}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setdeleteModal(false)}
                    ref={cancelDeleteModal}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>

      <form onSubmit={handleSubmit} className="mt-1 flex justify-center rounded-md border-gray-300 p-2">
        <div className="flex flex-col justify-center text-sm text-gray-600">
          <div className="flex flex-col justify-start">
              <input className="form-control block  w-full px-3 py-1.5 text-base font-normaltext-gray-700bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                type="file" accept=".jpg,.jpeg, .png" name="file" onChange={handleFileSelect} id="formFile" required />
          </div>

          <div className="flex flex-col-1 justify-start my-2">
                <div className="flex flex-col justify-center float-right text-xs">
                  {imgSize ? <span className={`text-xs mx-1 px-2 ${imgSize > 100 ? "bg-green-300 text-green-800" : "bg-red-300 text-red-800"} rounded-full py-1`}>
                    {imgSize + " KB"}
                  </span> : <span className="text-xs text-gray-800 rounded-full px-2">Hajmi kamida: 100KB</span>}
                </div>
                <div className="flex flex-col justify-center">
                  {imgWidth && imgHeight ? <span className={`text-xs px-2 mx-2 ${imgHeight > 580 && imgWidth > 580 ? "bg-green-300 text-green-800" : "bg-red-300 text-red-800"} rounded-full py-1`}>
                    {imgWidth + "px / " + imgHeight + "px"}
                  </span> : <span className="text-xs mx-2">Kamida 720x580px bo'lishi kerak </span>}
                </div>
            <div>
              <button type='SUBMIT' className={`${loading?"pointer-events-none bg-gray-400":"bg-blue-500"} float-right hover:bg-blue-700 text-white font-bold py-2.5 px-4 border border-blue-700 rounded`}>
                {loading?"Loading...":"Saqlash"}
              </button>

              </div>
          </div>
        <div className="flex flex-col-1 text-xs text-red-500">{imageError}</div>
        </div>
        
      </form>

      <section className="overflow-hidden text-gray-700 ">
        <div className="container px-5 py-2 mx-auto lg:pt-12 lg:px-32">
          <div className="flex justify-center xl:justify-start flex-wrap -m-1 md:-m-2">
            {loading ? "Loading ... " : images.map(item => (
              <div key={item.id} className="flex border m-2 rounded-md flex-wrap w-2/3 xl:w-1/6">
                <div className="w-full text-xs flex flex-row justify-center md:p-2">
                  { moment(item.created_at).format("DD/MM/YYYY HH:mm") }
                </div>
                <div className="w-full p-1 md:p-2">
                  <a className="spotlight" href={item.image}
                    data-src-800={item.image}
                    data-src-1200={item.image}
                    data-src-2400={item.image}
                    data-src-3800={item.image}>
                    <img alt="gallery" className="block object-cover object-center w-full h-full rounded-lg"
                      src={item.image} loading="lazy"/>
                  </a>
                </div>

                <div className="w-full py-1 flex flex-row justify-center md:p-2">
                  <a href={item.image} className="flex-col bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 mx-1 px-2 border border-gray-700 rounded">
                      <EyeIcon width={15} height={15} />
                  </a>
                  <button onClick={() => handleShowModal(item)} className="flex-col bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 mx-1 px-2 border border-blue-700 rounded">
                      <PencilIcon width={15} height={15} />
                  </button>
                  <button onClick={() => { setdeleteModal(true); setDeleteId(item.id) }} className="flex-col bg-red-500 hover:bg-red-700 text-white font-bold py-1 mx-1 px-2 border border-red-700 rounded">
                      <TrashIcon width={15} height={15} />
                  </button>
                </div>
                
            </div>
            ))}
          </div>
        </div>
      </section>      
    </div>
  )
}

export default Gallery