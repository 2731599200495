import { ArrowUturnLeftIcon } from '@heroicons/react/24/solid'
import React from 'react'
import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useEffect } from 'react'

function InnerMaster() {
  const { inner_id } = useParams()
  const navigate = useNavigate()

  const [section, setSection] = useState(null)
  const [meals, setMeals] = useState([])

  const fetchSection = async() => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/admin/menu/section/${inner_id}/`, {
        headers: {
          "Authorization": `Bearer ${sessionStorage.getItem('token')}`
        }
      })
      
      setSection(res.data)
      setMeals(res.data.meals)
  
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {

    fetchSection()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])




  return (
    <div className="h-full items-center">
    <div className="flex bg-slate-300 flex-row w-full justify-center items-center">
      <div className="flex-none float-left">
        <button onClick={() => navigate(-1)} className="p-2 flex flex-row bg-blue-100 border border-gray-500 m-1 rounded-md hover:text-gray-500 active:bg-blue-300"> <span className="pr-1"> <ArrowUturnLeftIcon width={25} height={25} />  </span> Orqaga</button>
      </div>
      <div className="flex-1 w-full text-center">
        Menu tuzish
      </div>
    </div>

    <div className="w-full p-2 mt-4 text-center">
      <h1> { section ? section.position : '' } - SECTION </h1> 
    </div>


      <div className="w-full flex flex-col justify-center items-center">
        <div className="grid border h-full rounded-md p-3 grid-cols-3 gap-2 mt-5 w-2/3">
            {meals.map(item => (
            <div key={item.id} className={`${item.id === section.main_meal ? "col-span-2" : ""} rounded-md h-64 border-slate-400 border`}>
              <div className="group object-cover relative w-full h-full rounded-md bg-gray-900" >
                            <img className="absolute inset-0 rounded-md h-full w-full object-cover group-hover:opacity-50" src={item.thumbnail} alt="" />
                            <div className="relative h-full px-2 flex flex-col justify-center items-center">
                                <div className="translate-y-8 px-2 border transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100">
                                    <h5 className="text-white text-md">{item.title}</h5>
                                    <h5 className="text-white text-sm">{item.summa} so'm</h5>
                                    <p className="text-xs text-white">{item.text}</p>
                                </div>
                            </div>
                          </div> 
            </div>
            ))}
        </div>
      </div>
  </div>
  )
}

export default InnerMaster