import React, { useState } from 'react'
import { isAlphanumeric, isEmpty } from 'validator'
import Swal from 'sweetalert2'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';
import { switchFunc } from '../../features/switch/switch'
import { switchFunc2 } from '../../features/switch/switch2'
import { useNavigate } from 'react-router-dom'

function UpdateProfile() {
  const [username, setUsername] = useState('admin')
  const [password, setPassword] = useState('') 
  const [newPassword, setNewPassword] = useState('')
  const navigate = useNavigate()  
  const [loading, setLoading] = useState(false)
  const [formErrorMessage, setFormErrorMessage] = useState("")



  const dispatch = useDispatch()
  const { isSwitch } = useSelector(state => state.isSwitch)
  const { isSwitch2 } = useSelector(state => state.isSwitch2)


  const showAlert = (title, text, icon) => {
    Swal.fire({
        title,
        text,
        icon,
        confirmButtonText: "OK",
      }).then(function () {
        window.location.reload()
        // Redirect the user
        // window.location.reload()
      });;
}




  //parolni o'zgarish
  const handleSubmit = async(e) => {
    e.preventDefault()

    if (isEmpty(username) && isEmpty(password)) return setFormErrorMessage("Username va Password bo'sh bo'lmasligi kerak!")
    if (!isAlphanumeric(username)) return setFormErrorMessage("Username faqat harf va sonlardan iborat bo'lishi kerak!")
    if (!isAlphanumeric(password)) return setFormErrorMessage("Parol faqat harf va sonlardan iborat bo'lishi kerak!")
    if (!isAlphanumeric(newPassword)) return setFormErrorMessage("Parol faqat harf va sonlardan iborat bo'lishi kerak!")

    console.log(password, newPassword)

      setLoading(true)
      await axios.put(`${process.env.REACT_APP_SERVER_HOST}/auth/change-password/`, { old_password: password, new_password: newPassword }, {
        headers: {
          "Authorization": `Bearer ${sessionStorage.getItem('token')}`
        }       
      })
      .then((res) => {
        setLoading(false)
        navigate('/')
        sessionStorage.clear();
        showAlert("Muvaffaqqiyatli", res.data.message, "success")
      }).catch((err) => {
        setLoading(false)
        setFormErrorMessage(err.response.data.old_password[0])
      }); 
      
      
  }


  //profile malumotlarini olish



  return (
    <div className="flex flex-col">
      <div className="mx-10 text-sm font-bold text-center">
          Delete buttons enabled
      </div>
    <div className="grid xl:mx-96 mt-5 mb-4 grid-cols-1 gap-2">
      <div className="mt-2 flex flex-row justify-center text-center mx-2">
        <div className="flex flex-col mx-2 justify-start">
            Kategoriyalar
        </div>
        <div className="flex w-30 mx-2 flex-col justify-end">
                <label className="inline-flex relative items-center mr-5 cursor-pointer">
                    <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={isSwitch}
                        readOnly
                    />
                    <div
                        onClick={() => {
                            isSwitch2 ? showAlert("Pastdagi kategoriyani iling!") : dispatch(switchFunc(!isSwitch))
                        }}
                        className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
                    ></div>
                    <span className="ml-2 w-10 text-sm font-medium text-gray-900">
                        { isSwitch ? "ON" : "OFF" }
                    </span>
                </label>
        </div>       
      </div>



      <div className="mt-2 flex flex-row justify-center mb-2 text-center mx-2">
        <div className="flex flex-col mx-2 justify-start">
            IN Kategoriya
        </div>
        <div className="flex w-30 mx-2 flex-col justify-end">
                <label className="inline-flex relative items-center mr-5 cursor-pointer">
                    <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={isSwitch2}
                        readOnly
                    />
                    <div
                        onClick={() => {
                            isSwitch ? dispatch(switchFunc2(!isSwitch2)) : showAlert("Avval yuqoridagi kategoriyani ochish kerak!")
                        }}
                        className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
                    ></div>
                    <span className="ml-2 w-10 text-sm font-medium text-gray-900">
                      { isSwitch2 ? "ON" : "OFF" }
                    </span>
                </label>
        </div>       
      </div>



    </div>

      <div className="mt-5 mx-10 font-bold text-center">
          POFILNI TAHRIRLASH
      </div>

    <div className="flex min-h-full items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-4">
          
          <form onSubmit={handleSubmit} className="mt-8 space-y-6" method="POST">
            <input type="hidden" name="remember" defaultValue="true" />
              <section className="border p-5">
                <div className="mx-auto my-3 relative">
                      <label className="my-1 ml-1 mb-2 text-xs" htmlFor="username">Hozirgi Username</label>
                      <div className='w-full'>
                          <input
                          id="username"
                          onChange={(e) => { setUsername(e.target.value); setFormErrorMessage(null) }}
                          value={username}
                          type="text"
                          placeholder="Hozirgi username"
                          className="w-full h-10 rounded-lg appearance-none border px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"/>
                      </div>
                  </div>


                  <div className="mx-auto my-3 relative">
                      <label className="my-1 w-full ml-1 mb-2 text-xs" htmlFor="username">Hozirgi Parol</label>
                      <div className='w-full'>
                          <input
                          onChange={(e) => { setPassword(e.target.value); setFormErrorMessage(null) }}
                          value={password}
                          type='text'
                          placeholder="Hozirgi parol"
                          className="w-full h-10 rounded-lg appearance-none border px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"/>
                      </div>
                  </div>


                  <div className="mx-auto my-3 relative">
                      <label className="my-1 w-full ml-1 mb-2 text-xs" htmlFor="username">Yangi Parol</label>
                      <div className='w-full'>
                          <input
                          onChange={(e) => { setNewPassword(e.target.value); setFormErrorMessage(null) }}
                          value={newPassword}
                          type='text'
                          placeholder="Yangi parol"
                          className="w-full h-10 rounded-lg appearance-none border px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"/>
                      </div>
                  </div>


                <small className='text-red-500'>{formErrorMessage}</small>
              </section>




            <div>
              <button
                type="submit"
                className={`${loading?"pointer-events-none bg-indigo-500":""} group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
              >
                {loading?"Loading ...":"O'zgartirish"}
              </button>
            </div>
          </form>
        </div>
      </div>

    </div>
  
  )
}

export default UpdateProfile