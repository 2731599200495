import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
function Home() {
const [data, setData] = useState([])

 //get data
 const fetchData = async() => {
  try {
    const cats = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/admin/menu/category/`, {
      headers: {
        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
      }
    })


    const recipes = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/admin/menu/`, {
      headers: {
        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
      }
    })


    const blogs = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/admin/home/blogs/`, {
      headers: {
        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
      }
    })

    const offers = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/admin/home/offer-controls/`, {
      headers: {
        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
      }
    })

    const gallery = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/admin/home/gallery/`, {
      headers: {
        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
      }
    })


    setData([
      { id: 1, name: "Barcha Kategoriyalar", count: cats.data.length, address: '/categories' },
      { id: 2, name: "Menudagi mahsulotlar", count: recipes.data.count, address: '/recipe' },
      { id: 3, name: "Barcha bloglar soni", count: blogs.data.length, address: '/blogs' },
      { id: 4, name: "Maxsus takliflar soni", count: offers.data.length, address: '/special' },
      { id: 5, name: "Galleriya rasmlar soni", count: gallery.data.length, address: '/gallery' }
    ])


  } catch (error) {
    console.log(error)
  }
}


useEffect(() => {
  fetchData()
}, [])



  return (
    <div className="flex flex-col justify-center">

      <div className="grid mb-2 grid-cols-1 gap-1">
        <div className="mx-10 text-xl font-bold float-left">
          Bosh sahifa
        </div>
      </div>


      <div className="flex flex-col mx-2 xl-mx-10 justify-start my-3 ">
        <div className="px-2 py-3 ">
          <div className="grid grid-cols-1 xl:grid-cols-3">

            

              {data.map(item => (
                <div key={item.id} className="text-center px-2 xl:px-8 py-3 xl:py-6">
                <div className="flex flex-col justify-center bg-white p-10 rounded-lg border shadow-md">
                  <h1 className="text-xl font-bold uppercase">
                    {item.name}
                  </h1>
                  <div className="mt-7 mb-6">
                    <p className="text-gray-600 text-4xl">
                      {item.count}
                    </p>
                  </div>
                  {/* <h3 className="text-xs uppercase">06-11-2000 16:54</h3>
                  <h2 className="tracking-wide">
                    Oxirgi qo'shilgan sana
                  </h2> */}
                  <Link to={item.address} className="bg-blue-400 text-white py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75">
                    KO'RISH
                  </Link>
                </div>
            </div>
              ))}

              <div className="text-center px-2 xl:px-8 py-3 xl:py-6">
                <div className="flex border-yellow-600 flex-col justify-center bg-white p-10 rounded-lg border-2 shadow-md">
                  <h1 className="text-xl font-bold uppercase">
                    QO'SHIMCHA STATISTIKA
                  </h1>
                  <div className="mt-7 mb-6">
                    <p className="text-gray-600 text-xl">
                      menu.favvora-urgench.uz
                    </p>
                  </div>
                  {/* <h3 className="text-xs uppercase">06-11-2000 16:54</h3>
                  <h2 className="tracking-wide">
                    Oxirgi qo'shilgan sana
                  </h2> */}
                  <a target='_blank' href={"https://analytics.google.com/analytics/web/#/p415677820/reports/intelligenthome"} className="bg-blue-400 text-white py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75" rel="noreferrer">
                    KO'RISH
                  </a>
                </div>
            </div>


            


          </div>
        </div>
      </div>








    </div>
  )
}

export default Home