import axios from 'axios'
import Swal from 'sweetalert2'
import React, { useState } from 'react'

function SmsSend() {
  const [smsError, setSmsError] = useState(null) 
  const [fileError, setFileError] = useState(null) 



  const [text, setText] = useState("")
  const [loading, setLoading] = useState(false)
  const [select, setSelect] = useState(null)
  const [count, setCount] = useState(0)

  const handleEditFileSelect = (e) => {
    setSelect(e.target.files[0]) 
    setFileError(null)
  }

  const showAlert = (title, icon, text) => {
    Swal.fire({
        title,
        text,
        icon,
        confirmButtonText: "OK",
      }).then(function () {
        window.location.reload()
      });;
}

const showErrorAlert = (title, icon, text) => {
  Swal.fire({
      title,
      text,
      icon,
      confirmButtonText: "OK",
    })
}



const handleSubmit = async() => {
  if (text.length < 3) return showAlert("SMS matni kamida 3 belgi bo'lishi kerak!", "error", "")
  if (!select) return showAlert("Excel fayl tanlang!", "error", "")


  
  var bodyFormData = new FormData()
  bodyFormData.append("file", select)
  bodyFormData.append("text", text)
    

    
    
    
    try {
      setLoading(true)
      const res = await axios({ 
        method: "post",
        url: `${process.env.REACT_APP_SERVER_HOST}/admin/sms/send/`,
        data: bodyFormData,
        headers: {
          "content-type": "multipart/form-data",
          "Authorization": `Bearer ${sessionStorage.getItem('token')}`
        }
      })
      console.log(res);
      if (!res.data) return showAlert("Xatolik yuz berdi!", "error", "")

      showAlert("SMS muvaffaqqiyatli yuborildi!", "success", "")
      setLoading(false)
      
    } catch (error) {
      setLoading(false)
      // if (error.response.statusText = "Bad Request") return showErrorAlert("Exceldagi ma'lumotlar juda ko'p! Kamida 200 gacha yuborishingiz mumkin!", "error", "")
      if (error.response.status === 400) return showErrorAlert("Ma'lumotlar notog'ri yuboryapsiz!", "error", "")
      if (error.response.status === 500) return showErrorAlert("Server xatosi!", "error", "")
      // if (error.response.status === 500) return showErrorAlert("Server xatosi!", "error", "")
      //   setFileError(error.response.data.file[0])
      //   setSmsError(error.response.data.text[0])
    }


  }

  return (
    <div className="flex flex-col justify-center">
      <div className="grid mt-10 mb-2 grid-cols-2 gap-2">
        <div className="mx-10 text-xl font-bold float-left">
            SMS yuborish
        </div>

        <div className="mx-10 text-sm font-bold float-right">
            {count} / 160
        </div>
      </div>

      <div className="flex">
       <textarea
          minLength={1}
          maxLength={160}
          onChange={(e) => { setText(e.target.value); setCount(e.target.value.length); setSmsError(null) }}
          cols={100}
          rows={5}
          placeholder="SMS matnini shu yerga kiriting..."
          className="resize w-full mx-5 mt-5 p-3 border bg-slate-50 rounded-md"
          defaultValue={text}
        />
      </div>
      <small className="mx-6 my-2 text-red-500">{smsError}</small>

    <div className="m-5 justify-start">
      <div className="w-96">
        <label htmlFor="formFile" className="form-label inline-block mb-2 text-gray-700">
          <small > Excel faylni yuklang </small>  
        </label>
        <input onChange={handleEditFileSelect} accept='.xlsx' className="form-control block w-full py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding rounded transition ease-in-out m-0
          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" type="file" id="formFile" />
          <small className="flex-col my-2 text-red-500">{fileError}</small>
        </div>
      </div>

      <small className="mx-6 text-red-500"></small>

    <div className="mx-5">
        <button
            onClick={handleSubmit}
            type="submit"
            className={`mx-5 ${loading?"pointer-events-none bg-blue-400":""} justify-center rounded-md border border-transparent bg-blue-700 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
          >
            {loading?"Loading ...":"Yuborish"}
          </button>
      </div>
    </div>
  )
}

export default SmsSend