import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    data: [],
    isLoading:false
}

export const basicData = createSlice({
  name: 'basic',
  initialState,
  reducers: {
    increment: (state, action) => {     
      state.basicData = action.payload
    }   
  },
})

export const { increment } = basicData.actions

export default basicData.reducer