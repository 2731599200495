import axios from "axios";
import { isLength } from 'validator'
import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2'

function About() {
  const [data, setData] = useState('')
  const [viewData, setViewData] = useState([])
  const [loading, setLoading] = useState(false)
  const [formErrorMessage, setFormErrorMessage] = useState("")
  const [letters, setLetters] = useState(0)

  const showAlert = (foo) => {
    Swal.fire({
        title: "Muvaffaqqiyatli",
        text: foo,
        icon: "success",
        confirmButtonText: "OK",
      }).then(function () {
        // Redirect the user
        // window.location.reload()
      });;
}

  const fetchData = async() => {
    await axios.get(`${process.env.REACT_APP_SERVER_HOST}/admin/home/abouts/`, {
      headers: {
        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
      }
    }).then((res) => {
      setViewData(res.data)
      setData(res.data[res.data.length - 1].text)
      setLetters(res.data[res.data.length - 1].text.length)
    })    
  }


  useEffect(() => {
    fetchData()
  }, [])


  const handleUpdate = async(e) => {
    setLoading(true)
    e.preventDefault()
    if (!isLength(data, { minLength: 70, maxLength: 1000 })) return setFormErrorMessage("Text hajmi 70 va 1000 ta belgi orasida bo'lishi kerak!")

    

      await axios.put(`${process.env.REACT_APP_SERVER_HOST}/admin/home/abouts/${viewData[viewData.length - 1].id}/`, { text: data }, {
        headers: {
          "Authorization": `Bearer ${sessionStorage.getItem('token')}`
        }
      })
      .then((res) => {
        setLoading(false)
        fetchData()
        showAlert("Ma'lumot muvaffaqqiyatli o'zgartirildi!")
      }).catch((err) => {
        setLoading(false)
        console.log(err)
      });   
  }


  return (
    <div className="flex flex-col justify-center">
    <div className="grid mt-0 mb-2 grid-cols-2 gap-2">
      <div className="mx-10 text-xl font-bold float-left">
          About bo'limi {` ( ${letters} )`}
      </div>
    </div>

    <form onSubmit={handleUpdate} method="post">
      <div className="flex mb-2">
        <textarea
            minLength={100}
            maxLength={700}
            cols={100}
            rows={10}
            className="resize w-full mx-1 mt-5 p-3 border bg-slate-50 rounded-md"
            defaultValue={data}
            onChange={(e) => { setData(e.target.value); setFormErrorMessage(null); setLetters(e.target.value.length) }}
          />
      </div>

          <small className="mx-2 text-red-500" >{formErrorMessage}</small>

      <div>
          <button
              type="submit"
              className={`${loading ? "pointer-events-none bg-gray-400" : "bg-blue-700"} w-32 mx-2 mt-2 justify-center rounded-md border border-transparent  px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
            >
              {loading?"Loading ...":"O'zgartirish"}
            </button>
        </div>
    </form>
    </div>
  );
}

export default About;


