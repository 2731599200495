import { createSlice } from '@reduxjs/toolkit'


const initialState ={
    data: [],
    isLoading:false
}

export const listSlice = createSlice({
  name: 'list',
  initialState,

  reducers: {
    increment: (state, action) => {     
      state.data = action.payload
    }
   
  },
})

// Action creators are generated for each case reducer function
export const { increment } = listSlice.actions

export default listSlice.reducer