import React, { Fragment, useEffect, useRef, useState } from "react";
import Swal from 'sweetalert2'

import { isLength } from "validator";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { BarLoader } from "react-spinners";

  function Categories() {

    const { isSwitch } = useSelector(state => state.isSwitch)

    const [fullCat, setFullCat] = useState([])
    
  //pagination
  const [currentPage, setCurrent] = useState(1)
  const [limit] = useState(10)
  const lastIndex = currentPage * limit
  const startIndex = lastIndex - limit

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  
 
  //modal form three
  const [deleteModal, setdeleteModal] = useState(false)
  const [open, setOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const cancelDeleteModal = useRef(null)
  const cancelEdit = useRef(null);
  const cancelButtonRef = useRef(null);

  const [deleteId, setDeleteId] = useState(null)
  
  const [title, setTitle] = useState(null);
  const [edittitle, setEditTitle] = useState(null);
  const [titleErrorMessage, setTitleError] = useState("");
  const [titleEditError, setEditError] = useState("");
  const [viewData, setWiewData] = useState({})


  const handleChange = (e) => {
    setTitle(e.target.value);
    setTitleError(null)
  }

  const handleEditChange = (e) => {
      setEditTitle(e.target.value); 
      setEditError(null)
  }


  const handleOpen = () => {
    setTitle(null)
    setOpen(true)
  }

  const hanldeEditOpen = async(item) => {
    setWiewData(item); 
    setEditModal(true)
    setEditTitle(item.title)
  }

  const showAlert = (foo) => {
    Swal.fire({
        title: "Muvaffaqqiyatli",
        text: foo,
        icon: "success",
        confirmButtonText: "OK",
      }).then(function () {
        fetchData()
        // Redirect the user
        // window.location.reload()
      });;
}

  
 //edit data
  const handleEditSubmit = async(e) => {
    e.preventDefault();
      if (!isLength(edittitle, { min: 3, max: 50 }))
        return setEditError(
          "Satr uzunligi 3 va 50 oralig'ida bo'lishi kerak"
        );
      
      var bodyFormData = new FormData();

      bodyFormData.append("title", edittitle)

      axios({
        method: "put",
        url: `${process.env.REACT_APP_SERVER_HOST}/admin/menu/category/${viewData.id}/`,
        data: bodyFormData,
        headers: {
              "content-type": "multipart/form-data",
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
      }).then((response) => {
          setEditModal(false)
          showAlert("Ma'lumot o'zgartirildi!")
          window.location.reload()
          fetchData()
        }).catch(function (res) {
          console.log(res)
          setEditError(res.response.data.title[0])
        });
  };
  





  //get data
  const fetchData = async() => {

    setLoading(true)
    await axios.get(`${process.env.REACT_APP_SERVER_HOST}/admin/menu/category/`, {
      headers: {
        "Authorization": `Bearer ${sessionStorage.getItem('token')}` 
      }
    }).then((res) => {
      setFullCat(res.data)
      const filterData = res.data.filter((item) => item.parent === null && item.position !== 1)
      setData(filterData)
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })



  }

  useEffect(() => {
    fetchData()
  }, [])


  //post data
  const handleSubmit = async(e) => {
    e.preventDefault();

      if (!isLength(title, { min: 4, max: 50 }))
        return setTitleError(
          "Satr uzunlig 4 va 50 oralig'ida bo'lishi kerak"
        );
        


      var bodyFormData = new FormData();
      bodyFormData.append("title", title)
      bodyFormData.append("position", fullCat.length + 1)
      setLoading(true)
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_HOST}/admin/menu/category/`,
        data: bodyFormData,
        headers: {
          "content-type": "multipart/form-data",
          "Authorization": `Bearer ${sessionStorage.getItem('token')}`
        }
      }).then((response) => {
          setLoading(false)
          setOpen(false)
          showAlert("Ma'lumot qo'shildi")
        }).catch((res) => {
          setLoading(false)
          setEditError(res.response.data.title[0])
        });
  };



//delete
  const handleDeleteData = (e) => {
    axios.delete(`${process.env.REACT_APP_SERVER_HOST}/admin/menu/category/${deleteId}/`, {
      headers: {
            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
          }      
    }).then(function (response) {
      setdeleteModal(false)
        showAlert("Ma'lumot o'chirildi!")
      }).catch(function (response) {
        console.log(response);
      });
  }


  var btns = []
  for (let i = 1; i <= Math.ceil(data.length / limit); i++) {
      btns.push(i)
  }

  const newData = data.slice(startIndex, lastIndex)


  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-center">
          <div className="flex w-full px-2 py-1 flex-row flex-1 float-left text-sm">
              <NavLink className="hover:underline flex flex-col text-gray-500 focus:text-blue-800 hover:text-blue-800 font-bold" to={"/categories"}> Kategoriyalar </NavLink> 
          </div>
          <div className="flex flex-col justify-center float-right text-center">
            <button onClick={() => handleOpen()} className="float-right text-xs xl:text-sm bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded">
                QO'SHISH +
            </button>
          </div>
        </div>

    <div className="overflow-x-auto">
    <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative"
            initialFocus={cancelButtonRef}
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300" 
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-5">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300" 
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <form
                  onSubmit={handleSubmit}
                  className="mt-8 space-y-6"
                  id="postForm"
                  method="POST"
                  >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg my-2 font-medium leading-6 text-gray-900"
                          >
                            Kategoriya qo'shish
                          </Dialog.Title>
                            <section>
                                  <div className="border md:col-span-2 md:mt-0">
                                      <div className="shadow sm:overflow-hidden sm:rounded-md">
                                        <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                                          <div className="grid grid-cols-3 gap-6">
                                            <div className="col-span-6 sm:col-span-6">
                                              <label
                                                htmlFor="company-website"
                                                className="block text-left text-sm font-medium text-gray-700"
                                              >
                                                Kategoriya Nomi
                                              </label>
                                              <div className="mt-1 flex rounded-md shadow-sm">

                                                <input 
                                                  type="text"
                                                  name="title"
                                                  onChange={handleChange} 
                                                  value={title ? title : ""}
                                                  id="company-website"
                                                  className="block rounded-lg appearance-none border px-3 py-2 w-full flex-1 rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                  placeholder="Taom nomi"
                                                  required
                                                />

                                              </div>
                                                <small className="float-left m-1 text-red-500">{titleErrorMessage}{''}</small>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                                        <button
                                        onClick={() => setOpen(false)}
                                          type="button"
                                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        >
                                          Orqaga
                                        </button>

                                        <button
                                          type="submit"
                                          className={`mt-2 ${loading?"pointer-events-none bg-blue-400":""} inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                                        >
                                          {loading?'Loading...':'Saqlash'}
                                        </button>
                                        </div>
                                      </div>

                                  </div>
                            </section>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                          </form>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>


        <Transition.Root show={deleteModal} as={Fragment}>
          <Dialog as="div" className="relative z-10" initialFocus={cancelDeleteModal} onClose={setdeleteModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>




        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Kategoriyani o'chirish
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Haqiqatdan ham shu kategoriyani o'chirmoqchimisiz? Keyin qayta tiklay olmaysiz!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleDeleteData}
                  >
                    O'chirish
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setdeleteModal(false)}
                    ref={cancelDeleteModal}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>








    <Transition.Root show={editModal} as={Fragment}>
          <Dialog
            as="div"
            className="relative"
            initialFocus={cancelEdit}
            onClose={setEditModal}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300" 
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300" 
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <form
                  onSubmit={handleEditSubmit}
                  className="mt-8 space-y-6"
                  method="POST"
                  >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg my-2 font-medium leading-6 text-yellow-900"
                          >
                            Kategoriya Tahrirlash
                          </Dialog.Title>
                            <section>
                                  <div className="mt-5 border md:col-span-2 md:mt-0">
                                      <div className="shadow sm:overflow-hidden sm:rounded-md">
                                        <div className="bg-white px-4 py-5">
                                          <div className="grid grid-cols-3 gap-6">
                                            <div className="col-span-6 sm:col-span-6">
                                              <label
                                                htmlFor="company-website"
                                                className="block text-left text-sm font-medium text-gray-700"
                                              >
                                                Kategoriya Nomi
                                              </label>
                                              <div className="mt-1 flex rounded-md shadow-sm">

                                                <input 
                                                  type="text"
                                                  name="title"
                                                  onChange={handleEditChange} 
                                                  value={edittitle}
                                                  id="company-website"
                                                  className="block rounded-lg appearance-none border px-3 py-2 w-full flex-1 rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                  placeholder="Taom nomi"
                                                />

                                              </div>
                                                <small className="text-red-500">{''}{titleEditError}</small>
                                            </div>
                                          </div>
                                          <div>                                         

            
                                          </div>
                                        </div>
                                        <div className="bg-gray-50 px-4 py-3 text-right">

                                        <button
                                          onClick={() => setEditModal(false)} 
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                          >
                                          Orqaga
                                        </button>

                                        <button
                                          type="submit"
                                          className="mt-2 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                        >
                                          Saqlash
                                        </button>
                                        </div>
                                      </div>

                                  </div>
                            </section>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </form>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

      {loading ? <div className="flex flex-col justify-center items-center h-[50vh] border">
            <BarLoader color="#36d7b7" />
            <h6 className="my-5 text-gray-500">
              Ma'lumotlar yuklanmoqda ...
            </h6>
        </div> : <div className="w-full inline-block align-middle">
        <div className="overflow-hidden rounded-lg"> 
          <table className="table-auto border my-5 w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-2 xl:px-4 p-2 text-sm font-bold text-left text-gray-500 uppercase">N</th>
                <th className="p-2 text-sm font-bold text-left text-gray-500 uppercase">Title</th>
                <th className="hidden md:table-cell xl:w-96 lg:w-96 md:w-64 p-4 text-left text-xs font-bold text-gray-500 uppercase">Text</th>
                <th className="w-36 p-4 text-center text-sm font-bold text-gray-500 uppercase">Action</th>
              </tr>
            </thead>
            <tbody>
            {newData.map((item, index) => (
              <tr key={item.id}>
                <td className="px-2 xl:px-4 py-2 text-sm font-medium text-gray-800">
                  {index + startIndex + 1}
                  </td>
                <td className="px-2 py-2 text-sm font-medium text-gray-800">
                  <Link className="hover:underline" to={`/categories/${item.id}`}>{item.title}</Link>
                </td>

                <td className="hidden md:table-cell xl:w-1/2 lg:w-96 md:w-64 px-2 py-2 text-xs xl:text-sm font-medium text-gray-800">
                  <p className="truncate xl:w-72 lg:w-96 md:w-64">
                      { fullCat.filter(element => element.parent === item.id).map(k => (
                        <Link to={`/categories/${k.parent}/${k.id}`} className="hover:underline text-xs mx-1 px-1 bg-gray-200 text-gray-800 rounded-full" key={k.id}> {k.title} </Link>
                      )) }
                  </p>
                </td>                

                <td className="w-36 px-2 py-2 text-sm font-medium text-gray-800">
                <button className="bg-gray-300 hover:bg-gray-500 text-white font-bold py-2 px-2 border border-gray-400 rounded">
                  <NavLink to={`/categories/${item.id}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-black w-4 h-4">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                  </NavLink>
                </button>

                  <button onClick={() => hanldeEditOpen(item)} className="mx-1 bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-2 border border-slate-500 rounded">

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                    </svg>

                  </button>

                  <button onClick={(e) => { setdeleteModal(true); setDeleteId(item.id) }} className={`mx-1 ${isSwitch ? "bg-red-700" : "pointer-events-none bg-gray-400"}  hover:bg-red-900 text-white font-bold py-2 px-2 border border-red-900 rounded`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
            </tbody>
          </table>

          <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
           
            <div className="flex flex-1 sm:items-center sm:justify-between">
              <div className="mr-5 flex flex-col justify-center">
                <p className="text-sm text-gray-700">
                  <span className="font-medium">{startIndex + 1}</span> / <span className="font-medium">{lastIndex}</span> of{' '}
                  <span className="font-medium">{data.length}</span>
                </p>
              </div>
              <div>
                <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                  
                  <button
                    onClick={() => (currentPage > 1)?setCurrent(currentPage - 1):''}
                    className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  

                  { btns.map(item => (
                      <button key={item} onClick={() => setCurrent(item)} aria-current="page" className="hidden sm:flex sm:flex-1 relative z-10 items-center border bg-white focus:border-indigo-500 focus:bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20">
                        {item}
                      </button>
                  )) }
                  
                  <button
                    onClick={() => (currentPage < btns.length)?setCurrent(currentPage + 1):''}
                    className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>}
      </div>
    </div>
  );
}

export default Categories;
