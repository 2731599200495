import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { showed } from '../../features/sitecontent/isShow'
import main_logo from '../Images/main.png'
import { HomeIcon, PresentationChartBarIcon, BriefcaseIcon, ChatBubbleBottomCenterIcon, PhotoIcon, XMarkIcon,
         BookOpenIcon, Squares2X2Icon, EnvelopeIcon, ArrowRightOnRectangleIcon, BookmarkSquareIcon, UserIcon, AdjustmentsHorizontalIcon} from '@heroicons/react/20/solid'
import { useDispatch } from 'react-redux'

function Sidebar() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {pathname} = window.location

    const menuItems = [
        { id: 1, title: "Home", logo: <HomeIcon width={20} height={20} />, addres: '/' },
        { id: 2, title: "About bo'limi", logo: <PresentationChartBarIcon width={20} height={20}/>, addres: '/about' },
        { id: 3, title: "Maxsus takliflar", logo: <BriefcaseIcon width={20} height={20}/>, addres: '/special' },
        { id: 4, title: "Bloglar", logo: <ChatBubbleBottomCenterIcon width={20} height={20}/>, addres: '/blogs' },
        { id: 5, title: "Galleriya", logo: <PhotoIcon width={20} height={20}/>, addres: '/gallery' },
        { id: 6, title: "Menu", logo: <BookOpenIcon width={20} height={20}/>, addres: '/recipe' },
        { id: 7, title: "Kategoriyalar", logo: <Squares2X2Icon width={20} height={20}/>, addres: '/categories' },
        // { id: 8, title: "Tanlanganlar", logo: <BookmarkSquareIcon width={20} height={20}/>, addres: '/saved' },
        { id: 8, title: "Menu tuzish", logo: <AdjustmentsHorizontalIcon width={20} height={20}/>, addres: '/menu' },
        { id: 9, title: "SMS jo'natmalar", logo: <EnvelopeIcon width={20} height={20}/>, addres: '/sms' }
    ]

    const logOutFunc = () => {
        sessionStorage.clear()
        navigate('/')
        window.location.reload()
    
    }
 
  return (
    <div>
    <div className="py-2 text-center flex flex-row justify-between">
        <NavLink onClick={() => dispatch(showed())} className="flex flex-col justify-center" to={'/'} title="home">
            <img src={main_logo} className="w-32 mx-9" alt="tailus logo" />
        </NavLink> 

        <button onClick={() => dispatch(showed())} className="xl:invisible hover:bg-slate-50 active:bg-slate-200 h-12 my-2 lg:invisible flex flex-col border px-3 justify-center">
            <XMarkIcon width={25} height={25} />
        </button>
    </div> 

    <ul className="tracking-wide">
       {menuItems.map(item => (
         <li key={item.id}>
            <NavLink onClick={() => dispatch(showed())} to={item.addres} aria-label="dashboard" className={`relative px-4 py-3 flex items-center space-x-4 rounded-xl text-gray-600 bg-gradient-to-r hover:from-sky-600
            hover:text-white hover:to-cyan-400 transition focus:from-sky-600 focus:to-cyan-400 ${(pathname === item.addres)?"bg-sky-600 text-white":''} focus:text-white`}>               
                { item.logo }
                <span className="-mr-1 font-medium">{item.title}</span>
            </NavLink>
        </li>
       ))}
    </ul> 

    <div className="px-6 -mx-6 pt-2 mt-2 flex justify-between items-center border-t">
        <button onClick={logOutFunc} className="px-4 py-2 flex items-center space-x-4 rounded-md text-gray-600 group">     
            <ArrowRightOnRectangleIcon width={20} height={20}/>           
            <span className="group-hover:text-gray-700">Logout</span>
        </button>
    </div>

    <div className="px-6 -mx-6 mt-2 flex justify-between items-center border-t">
        <NavLink to={'/profile'} className="px-4 py-2 flex items-center space-x-4 rounded-md text-gray-600 group">     
            <UserIcon width={20} height={20}/>           
            <span className="group-hover:text-gray-700">SETTINGS</span>
        </NavLink>
    </div>
</div>
  )
}

export default Sidebar